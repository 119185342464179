import { List as ListIcon } from "react-feather";

import { PrintBarcodePage } from "pages/printBarcode";
import { CharacteristicsPage } from "pages/characteristics";
import { BrandsPage } from "pages/brands";
import { BrandSynonymsPage } from "pages/brandSynonyms";
import { NomenclaturePage } from "pages/nomenclature";
import { PriceRequestLogPage } from "pages/priceRequestLog";
import { CrossPage } from "pages/cross";
import { CrossUploadPage } from "pages/crossUpload";
import { AdminsPage } from "pages/admins";
import { AvitoPage } from "pages/avito";
import { AvitoOnePage } from "pages/avitoOne";

const brandsRoutes = {
  path: "/reference/brands",
  name: "Бренды",
  title: "Бренды",
  component: BrandsPage,
};

const brandSynonymsRoutes = {
  path: "/reference/brandSynonyms",
  name: "Синонимы брендов",
  title: "Синонимы брендов",
  component: BrandSynonymsPage,
};

const nomenclatureRoutes = {
  path: "/reference/nomenclature",
  name: "Номенклатура",
  title: "Номенклатура",
  component: NomenclaturePage,
};

const avitoRoutes = {
  path: "/reference/avito",
  name: "Авито",
  title: "Авито",
  component: AvitoPage,
};

const avitoOneRoutes = {
  path: "/reference/avito/:id",
  name: "Авито объявление",
  title: "Авито объявление",
  component: AvitoOnePage,
};

const avitoNewRoutes = {
  path: "/reference/avito/new",
  name: "Авито новое объявление",
  title: "Авито новое объявление",
  component: AvitoOnePage,
};

const printBarcodeRoutes = {
  path: "/reference/nomenclature/:id/print",
  name: "Печать штрих кода",
  title: "Печать штрих кода",
  component: PrintBarcodePage,
};

const characteristicsRoutes = {
  path: "/reference/characteristics",
  name: "Характеристики",
  title: "Характеристики",
  component: CharacteristicsPage,
};

const priceRequestLogRoutes = {
  path: "/reference/priceRequestLog",
  name: "История запросов",
  title: "История запросов",
  component: PriceRequestLogPage,
};

const crossRoutes = {
  path: "/reference/cross",
  name: "Кроссы",
  title: "Кроссы",
  component: CrossPage,
};

const crossRoutesUpload = {
  path: "/reference/cross/upload",
  name: "Загрузки кроссов",
  title: "Загрузки кроссов",
  component: CrossUploadPage,
};

const adminsRoutes = {
  path: "/reference/admins",
  name: "Пользователи",
  title: "Пользователи",
  component: AdminsPage,
};

export const referenceMenu = {
  path: "/reference",
  name: "Справочники",
  title: "Справочники",
  icon: ListIcon,
  children: [
    brandsRoutes,
    brandSynonymsRoutes,
    priceRequestLogRoutes,
    crossRoutes,
    nomenclatureRoutes,
    avitoRoutes,
    characteristicsRoutes,
    adminsRoutes,
  ],
};

export const referenceRoutes = [
  brandsRoutes,
  brandSynonymsRoutes,
  nomenclatureRoutes,
  avitoRoutes,
  avitoNewRoutes,
  avitoOneRoutes,
  characteristicsRoutes,
  priceRequestLogRoutes,
  crossRoutes,
  crossRoutesUpload,
  printBarcodeRoutes,
  adminsRoutes,
];
