import { CloseButton } from "components/CloseButton";
import { MySpinner } from "components/MySpinner";
import React, { useEffect, useState } from "react";
import { CheckCircle } from "react-feather";
import { toast } from "react-toastify";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { sendUtd } from "services/api";

export const SendUtdModal = ({ id, order, user }) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [isSent, setIsSent] = useState(false);

  useEffect(() => {
    setIsSent(order.utdIsSent);
  }, [order.utdIsSent]);

  const utdSendHandler = () => {
    setLoading(true);
    sendUtd({ id })
      .then((res) => {
        if (res.utdIsSent) {
          toast.success("УПД успешно отправлен");
          setIsSent(res.utdIsSent);
        } else {
          toast.error("Ошибка при отправке УПД. Возможно один из адресов некорректен.");
        }

      })
      .catch(() => {
        toast.error("Ошибка при отправке УПД. Возможно один из адресов некорректен.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Button color="primary" onClick={() => setOpen(true)} className="button-wide">
        {isSent && (
          <CheckCircle color="green" className="sent-diadoc" />
        )}
        Отправить УПД
      </Button>

      <Modal isOpen={open} toggle={() => setOpen(false)}>
        <ModalHeader>
          <div className="modal-title-text">Отправить УПД на email</div>
          <CloseButton onClick={() => setOpen(false)} />
        </ModalHeader>
        <ModalBody className="m-3">
          {loading && <MySpinner overlay />}

          <p>УПД будет отправлен на Email:</p>
          <ul>
            {user?.emailsForDocuments?.map(email => <li key={email}>{email}</li>)}
          </ul>

          <div className="d-flex justify-content-between mt-3">
            <Button onClick={() => setOpen(false)} color="secondary">
              Закрыть
            </Button>
            <Button onClick={utdSendHandler} color="primary">
              Отправить
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};
