import { MySpinner } from "components/MySpinner";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  Label,
  Row,
} from "reactstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import Select from "react-select";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { EnteringBalancesTable } from "features/EnteringBalance/EnteringBalancesTable";
import { getEnteringBalances } from "services/apiSupply";
import useQuery from "utils/useQuery";
import { Pagination } from "components/Pagination";
import { useUser } from "features/Users/UserContext";

import "./enteringBalance.scss";

export const EnteringBalancePage = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const query = useQuery(useLocation);
  const { admins } = useUser();
  const [enteringBalances, setEnteringBalances] = useState([]);
  const [enteringBalancesCount, setEnteringBalancesCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [admin, setAdmin] = useState(null);
  const [search, setSearch] = useState("");
  const [number, setNumber] = useState("");
  const [isShowReset, setIsShowReset] = useState(false);

  const [currentPage, setCurrentPage] = useState(
    query.get("page") ? Number(query.get("page")) : 1
  );
  const getPageSize = localStorage.getItem("pageSize") ? JSON.parse(localStorage.getItem("pageSize")) : {};
  const [pageSize, setPageSize] = useState(getPageSize[pathname] || 10);

  const getAndSetEnteringBalances = () => {
    getEnteringBalances({
      paginationCount: pageSize,
      paginationOffset: (currentPage - 1) * pageSize,
      search,
      number,
      admin: admin?.value,
    })
      .then((res) => {
        setEnteringBalances(res.items);
        setEnteringBalancesCount(res.totalItems);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setLoading(true);
    getAndSetEnteringBalances();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize]);

  useEffect(() => {
    const url = new URLSearchParams();
    if (currentPage !== 1) {
      url.append("page", currentPage);
    }

    if (search) {
      url.append("search", search);
    }

    if (admin?.value) {
      url.append("admin", admin?.value);
      url.append("adminName", admin?.label);
    }

    if (number) {
      url.append("number", number);
    }

    if (url) {
      history.push(`${pathname}?${url}`);
    } else {
      history.push(pathname);
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize, search, number, admin?.value]);

  const selectHandler = (val, res) => {
    switch (res.name) {
      case "admin":
        setAdmin(val);
        break;
      default:
        break;
    }
  };

  const isSearch = () => {
    if (search || number || admin?.value) {
      return true;
    }
    return false;
  };

  const searchHandler = () => {
    if (!isSearch()) return;
    if (currentPage !== 1) {
      setCurrentPage(1);
      return;
    }
    setLoading(true);
    getAndSetEnteringBalances();
  };

  const handleKeypress = (e) => {
    if (e.charCode === 13) {
      e.preventDefault();
      searchHandler();
    }
  };

  useEffect(() => {
    setIsShowReset(isSearch());
    if (!isSearch()) {
      setLoading(true);
      getAndSetEnteringBalances();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, number, admin]);

  const resetFilter = () => {
    setSearch("");
    setNumber("");
    setAdmin({ label: "", value: "" });
  };

  const numberHandler = (e) => {
    setNumber(e.target.value.replace(/[^+\d]/g, ""));
  };

  return (
    <Container fluid className="p-0 entering-balance">
      {loading && <MySpinner overlay />}
      <Card className="mb-0 filter">
        <CardBody>
          <h1 className="h3 mb-3">Ввод остатков</h1>

          <Form className="mb-3">
            <Row>
              <Col>
                <Label>Номер</Label>
                <Input
                  name="number"
                  placeholder="По номеру"
                  value={number}
                  onChange={numberHandler}
                  onKeyPress={handleKeypress}
                />
              </Col>
              <Col>
                <Label>Артикул</Label>
                <Input
                  name="search"
                  placeholder="По артикулу"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  onKeyPress={handleKeypress}
                />
              </Col>
              <Col md={3}>
                <Label>Создал</Label>
                <Select
                  name="admin"
                  placeholder="Создал ввод остатков"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={admins}
                  value={admin}
                  defaultValue={[{ value: "", label: "" }]}
                  onChange={selectHandler}
                  isClearable
                />
              </Col>
              <Col>
                <InputGroup className="with-label">
                  {isShowReset && (
                    <Button color="danger" onClick={() => resetFilter()}>
                      <FontAwesomeIcon icon={faWindowClose} />
                    </Button>
                  )}
                  <Button
                    color="primary"
                    onClick={searchHandler}
                    className="button-wide"
                  >
                    Поиск
                  </Button>
                </InputGroup>
              </Col>
            </Row>
          </Form>
          <Row>
            <Col md="2">
              <Link
                to={`/stock/enteringBalance/new`}
                color="primary"
                className="button-wide btn btn-primary"
              >
                Добавить ввод остатков
              </Link>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <div>
        <EnteringBalancesTable enteringBalances={enteringBalances} />

        <Card>
          <CardBody>
            <Pagination
              totalCount={enteringBalancesCount}
              currentPage={currentPage}
              pageSize={pageSize}
              setCurrentPage={setCurrentPage}
              onChangePageSize={setPageSize}
            />
          </CardBody>
        </Card>
      </div>
    </Container>
  );
};
