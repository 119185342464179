import { Button, Card, CardBody, Col, Container, Input, InputGroup, Label, Row } from "reactstrap";
import { Formik } from "formik";
import { object, array, string, setLocale } from "yup";
import Select from "react-select";
import { toast } from "react-toastify";
import classNames from "classnames";

import { locale } from "utils/validationLocale";
import { MySpinner } from "components/MySpinner";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Helmet } from "react-helmet";
import { HeaderPage } from "components/HeaderPage";
import { SelectCustomer } from "features/Dashboard/SelectCustomer";
import { TableButton } from "components/TableButton";
import { Trash } from "react-feather";
import { getWarehouses } from "services/apiWarehouse";
import { createOrUpdateSendingPriceList, getSendingPriceListOne } from "services/api";

setLocale(locale);

const SendingPriceListSchema = object().shape({
  name: string().min(2).required(),
  subject: string().min(2).required()
});

export const SendingPriceListOnePage = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [sendingPriceList, setSendingPriceList] = useState(null);
  const [warehouse, setWarehouse] = useState(null);
  const [warehouses, setWarehouses] = useState([]);

  const getAndSetSendingPriceList = () => {
    setLoading(true);
    getSendingPriceListOne(id)
      .then((res) => {
        setSendingPriceList(res);
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    getAndSetSendingPriceList();
  }, []);

  const submitHandler = (values) => {
    setLoading(true);
    createOrUpdateSendingPriceList({
      id: values.id,
      customerId: values.customer?.value,
      name: values.name,
      specification: {
        emails: values.emails,
        warehouses: values.warehouses?.map(v => v.value),
        time: values.time,
        subject: values.subject
      }
    })
      .then(res => {
        setSendingPriceList(res);
        toast.success("Изменения успешно сохранены");
      })
      .catch(e => toast.error(e.response?.data?.message))
      .finally(() => setLoading(false))
  };

  const getAndSetWarehouses = () => {
    getWarehouses()
      .then(setWarehouses)
      .catch((e) => console.error(e));
  };

  useEffect(() => {
    getAndSetWarehouses();
  }, []);

  const renderTitle = () => {
    if (!id) {
      return `Новая рассылка`;
    }

    return `Рассылка "${sendingPriceList?.name}"`;
  };

  return (
    <Container fluid className="p-0 sending-price-list-one">
      <Helmet>
        <title>{renderTitle()}</title>
      </Helmet>

      <Formik
        initialValues={{
          id: sendingPriceList?.id || "",
          name: sendingPriceList?.name || "",
          customer: {
            label: sendingPriceList?.customer?.name,
            value: sendingPriceList?.customer?.id
          } || null,
          emails: sendingPriceList?.specification?.emails || [],
          warehouses: sendingPriceList?.specification?.warehouses.map(warehouseId => {
            const warehouse = warehouses.find(v => v.id === warehouseId);
            return { label: warehouse?.name, value: warehouse?.id };
          }) || [],
          time: [7, 11, 14, 17, 20],
          subject: sendingPriceList?.specification?.subject || ""
        }}
        validationSchema={SendingPriceListSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          console.log(values);
          submitHandler(values, resetForm);
          setSubmitting(false);
        }}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>

            {loading && <MySpinner overlay />}

            <HeaderPage title={renderTitle()} />

            <Card className="main-form mb-0">
              <CardBody className="pb-3">
                <Row>
                  <Col>
                    <Label className="text-sm-right">Наименование</Label>
                    <Input
                      id="name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                      invalid={
                        errors.name && touched.name ? true : false
                      }
                    />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col>
                    <SelectCustomer
                      label="Клиент"
                      customer={values.customer}
                      setCustomer={val => setFieldValue("customer", val)}
                    />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col md="12" className="elem-field">
                    <Label className="text-sm-right">Email</Label>
                  </Col>
                </Row>
                <Row>
                  <Col md="12" className="elem-field">
                    <table className="table table-hover">
                      <tbody>
                        {values.emails.map((email, i) => (
                          <tr key={email}>
                            <td>{email}</td>
                            <td>
                              <TableButton
                                Icon={Trash}
                                onClick={() => {
                                  const newEmails = [...values.emails];
                                  newEmails.splice(i, 1);
                                  setFieldValue("emails", newEmails);
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <InputGroup>
                      <Input
                        name="email"
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        invalid={
                          errors.email && touched.email ? true : false
                        }
                      />
                      <Button onClick={() => {
                        setFieldValue("emails", [...values.emails, email]);
                        setEmail("");
                      }}>Добавить</Button>
                    </InputGroup>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col>
                    <Label className="text-sm-right">Склады</Label>
                  </Col>
                </Row>
                <Row>
                  <Col md="12" className="elem-field">
                    <table className="table table-hover">
                      <tbody>
                        {values.warehouses.map((warehouse, i) => (
                          <tr key={warehouse.value}>
                            <td>{warehouse.label}</td>
                            <td>
                              <TableButton
                                Icon={Trash}
                                onClick={() => {
                                  const newWarehouse = [...values.warehouses];
                                  newWarehouse.splice(i, 1);
                                  setFieldValue("warehouses", newWarehouse);
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <InputGroup>
                      <Select
                        isClearable
                        name="warehouse"
                        placeholder="Выберите склад"
                        className={classNames("react-select-container", { "is-invalid": errors.typology && touched.typology ? true : false })}
                        classNamePrefix="react-select"
                        options={warehouses.map((item) => {
                          return {
                            label: item.name,
                            value: item.id,
                          };
                        })}
                        value={warehouse}
                        onChange={val => {
                          setFieldValue("warehouses", [...values.warehouses, val]);
                          setWarehouse(null);
                        }}
                      />
                    </InputGroup>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col>
                    <Label className="text-sm-right">Тема письма</Label>
                    <Input
                      id="subject"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.subject}
                      invalid={
                        errors.subject && touched.subject ? true : false
                      }
                    />
                  </Col>
                </Row>

                <div className="d-flex justify-content-end mt-3">
                  <Button type="submit" color="primary">
                    Сохранить рассылку
                  </Button>
                </div>
              </CardBody>
            </Card>
          </form>
        )}
      </Formik>
    </Container >
  );
}