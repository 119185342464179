import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { BarChart } from "react-feather";
import { Cut } from "components/Cut";
import { getWarehouses } from "services/apiWarehouse";

import { ChangeWarehouse } from "../ChangeWarehouse";
import "./writeOffProductTable.scss";

export const WriteOffProductTable = ({
  products,
  carriedOut,
  setProducts,
  setLoading,
}) => {
  const [warehouses, setWarehouses] = useState([]);

  const getAndSetWarehouses = () => {
    getWarehouses()
      .then(setWarehouses)
      .catch((e) => console.error(e));
  };

  useEffect(() => {
    getAndSetWarehouses();
    // eslint-disable-next-line
  }, []);

  const getExistRender = (row) => {
    if (row.inStockAll) {
      if (row.inStockAll === row.inStock) {
        return row.inStock;
      }
      return `${row.inStock || 0} (${row.inStockAll})`;
    }
    return row.inStock;
  };

  const tableProductsColumns = [
    {
      dataField: "index",
      text: "№",
      headerStyle: (colum, colIndex) => {
        return { width: "2%" };
      },
      editable: false,
      footer: () => null,
    },
    {
      dataField: "article",
      text: "Артикул",
      formatter: (cell, row) => row.nomenclature?.article,
      headerStyle: (colum, colIndex) => {
        return { width: "13%" };
      },
      editable: false,
      footer: () => null,
    },
    {
      dataField: "brand",
      text: "Бренд",
      formatter: (cell, row) => row.nomenclature?.brand,
      headerStyle: (colum, colIndex) => {
        return { width: "13%" };
      },
      editable: false,
      footer: () => null,
    },
    {
      dataField: "name",
      text: "Наименование",
      formatter: (cell, row) => <Cut>{row.nomenclature?.name}</Cut>,
      headerStyle: (colum, colIndex) => {
        return { width: "20%" };
      },
      editable: false,
      footer: () => null,
    },
    {
      dataField: "inStock",
      text: "Ост.",
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
      formatter: (cell, row) => (
        <div className="warehouse-container">
          {(cell || row.inStockAll) && (
            <BarChart
              color={cell > 0 ? "green" : "red"}
              count={getExistRender(row)}
            />
          )}
        </div>
      ),
      headerClasses: () => "right",
      classes: (cell) => "right",
      footer: () => null,
      editable: false,
    },
    {
      dataField: "count",
      text: "Кол-во",
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      headerClasses: () => "right",
      footerClasses: () => "right",
      classes: () => "editable right",
      formatter: (cell, row) => row.count,
    },
    {
      dataField: "warehouse",
      text: "Склад",
      headerStyle: (colum, colIndex) => {
        return { width: "17%" };
      },
      formatter: (cell, row) => {
        if (carriedOut) {
          return cell?.name;
        }
        return (
          <ChangeWarehouse
            row={row}
            warehouses={warehouses}
            setProducts={setProducts}
            setLoading={setLoading}
          />
        );
      },
      footer: () => null,
      editable: false,
    },
    {
      dataField: "",
      text: "",
      headerStyle: (colum, colIndex) => {
        return { width: "40%" };
      },
      editable: false,
      footer: (columnData) => <></>,
    },
  ];

  const rowClasses = (row, rowIndex) => {
    const foundCarriedOut = products.find((item) => item.carriedOut);

    if (foundCarriedOut && !row.carriedOut) {
      return "error";
    }
  };

  return (
    <>
      <BootstrapTable
        wrapperClasses="write-off-product"
        keyField="id"
        data={products}
        columns={tableProductsColumns}
        bootstrap4
        bordered={false}
        rowClasses={rowClasses}
      />
    </>
  );
};
