import React, { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import classNames from "classnames";
import DatePicker, { registerLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";
import {
  Button,
  Container,
  Input,
  Row,
  Label,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonGroup,
  Alert,
} from "reactstrap";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import { CheckCircle, Check } from "react-feather";
import "react-datepicker/dist/react-datepicker.css";

import {
  getAddresses,
  getCheck,
  getInvoice,
  getOrder,
  getStatuses,
  getUpd,
  sendToDiadoc,
  updateOrder,
  baseUrl,
  getContracts,
  setStatus,
  createReserve,
  carryOutOrder,
  addBonus,
  updateComment,
  sendTo1S,
  updateDeliveryType,
  getAccounts,
  getTorg1T,
} from "services/api";
import { MySpinner } from "components/MySpinner";
import { Status } from "components/Status";
import {
  dateFormat,
  dateFormatU,
  dateTimeFormat,
  priceFormat,
} from "utils/format";
import { useOrders } from "features/Orders/OrdersContext";
import { DELIVERY, SKLAD, addressesMap } from "features/Dashboard/OrderForm";
import { SelectCustomer } from "features/Dashboard/SelectCustomer";
import { SendInvoiceModal } from "features/Orders/SendInvoiceModal";
import { LabelModal } from "features/Orders/LabelModal";
import { ChangeLogOrders } from "features/Orders/ChangeLogOrders";
import { OrderProductTable } from "features/Orders/OrderProductTable";
import { HeaderPage } from "components/HeaderPage";
import { Checkbox } from "components/Checkbox";
import { useUser } from "features/Users/UserContext";
import { CreateAddressModal } from "components/CreateAddressModal";
import { DeliveryBlock } from "features/Orders/DeliveryBlock";
import { SendUtdModal } from "features/Orders/SendUtdModal";

import "./orderOne.scss";

registerLocale("ru", ru);

export const PAYMENTS = [
  { label: "Нал", value: 1 },
  { label: "Безнал", value: 2 },
  { label: "Терминал", value: 3 },
];

export const TRANSPORT_COMPANY = [
  { label: "Деловые линии", value: "Деловые линии" },
  { label: "Байкал", value: "Байкал" },
  { label: "СДЭК", value: "СДЭК" },
  { label: "УТС", value: "УТС" },
  { label: "ПЭК", value: "ПЭК" },
  { label: "КИТ", value: "КИТ" },
  { label: "БСД", value: "БСД" },
  { label: "ЦАП", value: "ЦАП" },
  { label: "НОРД ВИЛ", value: "НОРД ВИЛ" },
  { label: "Уральский Меридиан", value: "Уральский Меридиан" },
  { label: "ТЛК Альтернатива", value: "ТЛК Альтернатива" },
  { label: "ТК Мейджик Транс", value: "ТК Мейджик Транс" },
  { label: "Другая ТК", value: "Другая ТК" },
];

export const OrderOnePage = () => {
  const { id } = useParams();
  const { setStatuses, status, setLoading, loading, setProducts, products } =
    useOrders();
  const { user, admins } = useUser();
  const [order, setOrder] = useState({});
  const [comment, setComment] = useState("");
  const [customer, setCustomer] = useState(null);
  const [account, setAccount] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [shippingDate, setShippingDate] = useState("");
  const [admin, setAdmin] = useState(null);
  const [sklad, setSklad] = useState(null);
  const [contract, setContract] = useState(null);
  const [contracts, setContracts] = useState([]);
  const [date, setDate] = useState("");
  const [regulated, setRegulated] = useState(true);
  const [bonus, setBonus] = useState(0);
  const [openModalLabel, setOpenModalLabel] = useState(false);
  const [noEdit, setNoEdit] = useState(false);
  const [error, setError] = useState("");
  const [priceNds, setPriceNds] = useState(false);
  const [storeKeeper, setStoreKeeper] = useState(null);
  const [payment, setPayment] = useState("");

  const getAndSetStatuses = () => {
    getStatuses()
      .then((res) => {
        setStatuses(res);
      })
      .catch((e) => toast.error(e?.response?.data?.message));
  };

  const getAndSetOrder = () => {
    getOrder(id)
      .then((res) => {
        setOrder(res);
      })
      .catch((e) => toast.error(e?.response?.data?.message))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setLoading(true);
    getAndSetStatuses();
    return () => setProducts([]);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setPayment(order.payment?.id);
    setComment(order.comment || "");
    setCustomer({ value: order.customer?.id, label: order.customer?.name });

    setAccount({ value: order.account?.id, label: order.account?.name });

    if (order.admin) {
      setAdmin({ value: order.admin?.id, label: order.admin?.name });
    }

    setContracts(
      order.customer?.contracts.map((val) => {
        return {
          label: val.label,
          value: val.id,
        };
      })
    );

    setContract(order.contract ? { value: order.contract?.id, label: order.contract?.name } : null);

    setDate(dateFormat(order.date));
    setProducts(order.products);
    setShippingDate(dateTimeFormat(order.shippingDate));
    setRegulated(order.regulated);
    setBonus(order.bonus || "");
    setPriceNds(order.priceNds || false);
    if (order.storeKeeper) {
      setStoreKeeper({
        value: order.storeKeeper?.id,
        label: order.storeKeeper?.name,
      });
    }

    if (order?.account) {
      setAccount({ value: order?.account?.id, label: order?.account?.title || "Главное подразделение" });
    }

    getAndSetAccounts(order?.customer?.id);



    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  useEffect(() => {
    getAndSetOrder();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const orderHandler = (address = null) => {
    if (!order.id) return;
    setLoading(true);
    updateOrder({
      id: order.id,
      comment,
      admin: admin?.value,
      storeKeeper: storeKeeper?.value,
      date,
      shippingDate,
      sklad: sklad?.value,
      contract: contract?.value,
      regulated,
      bonus: parseInt(bonus),
      priceNds,
    })
      .then((res) => {
        setOrder(res);
        toast.success("Данные заказа успешно сохранены");
      })
      .catch((e) => toast.error(e.response?.data?.message))
      .finally(() => setLoading(false));
  };



  const selectHandler = (val, res) => {
    switch (res.name) {
      case "admin":
        setAdmin(val);
        break;
      case "storeKeeper":
        setStoreKeeper(val);
        break;
      case "sklad":
        setSklad(val);
        break;
      case "contract":
        setContract(val);
        break;
      default:
        break;
    }
  };

  const changeHandler = (e) => {
    switch (e.target.name) {
      case "comment":
        setComment(e.target.value);
        break;
      case "bonus":
        setBonus(e.target.value);
        break;
      case "date":
        setDate(e.target.value);
        break;
      case "shippingDate":
        setShippingDate(e.target.value);
        break;
      default:
        break;
    }
  };

  const diadocHandler = () => {
    if (!id) return;
    setLoading(true);
    sendToDiadoc({ id })
      .then((res) => {
        toast.success("Документ отправлен в диадок");
        setOrder(res);
      })
      .catch((e) => {
        if (e.response?.data?.message) {
          setError(e.response?.data?.message);
        } else {
          setError("Ошибка при отправке в диадок");
        }
      })
      .finally(() => setLoading(false));
  };

  const updHandler = () => {
    getUpd({ id })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `УПД № ${order.number} от ${order.shippingDateText}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getTorg1THandler = () => {
    getTorg1T({ id })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `ТТН № ${order.number} от ${order.shippingDateText}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const invoiceHandler = (withSign = false) => {
    getInvoice({
      id,
      withSign: withSign ? 1 : 0,
    })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Счет № ${order.number}.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const checkHandler = () => {
    getCheck({ id })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Чек № ${order.number}.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const validProvider = () => {
    return products.find((val) => !val.provider);
  };

  const setBonusHandler = () => {
    setLoading(true);
    addBonus({
      id,
      bonus,
    })
      .then((res) => {
        setOrder(res);
        toast.success("Бонус успешно сохранен");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const setCommentHandler = () => {
    setLoading(true);
    updateComment({
      id,
      comment,
    })
      .then((res) => {
        setOrder(res);
        toast.success("Комментарий успешно сохранен");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const paymentHandler = (payment) => {
    if (payment?.value === 2) {
      toast.error("нельзя выбрать безнал");
      return;
    }
    setLoading(true);
    updateOrder({
      id: order.id,
      payment: payment?.value,
    })
      .then((res) => {
        setOrder(res);
        toast.success("Способ оплаты успешно сохранен");
      })
      .catch((e) => toast.error(e.response?.data?.message))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (order.status?.sort >= 4) {
      setNoEdit(true);
    } else {
      setNoEdit(false);
    }
  }, [order.status]);

  const accountsMap = (res) =>
    res.map((item) => {
      return { value: item.id, label: item.title || "Главное подразделение" };
    });

  const getAndSetAccounts = (customerId = null) => {
    getAccounts(customerId || customer?.value)
      .then((res) => {
        if (res.length < 2) return;
        setAccounts(accountsMap(res));
        if (!customerId) {
          setAccount({ value: res[0].id, label: res[0].title || "Главное подразделение" });
        }

      })
      .finally(() => setLoading(false));
  };

  const customerHandler = (val) => {
    setCustomer(val);
    setLoading(true);

    updateOrder({
      id: order.id,
      customer: val?.value,
    })
      .then((res) => {
        setOrder(res);
        toast.success("Клиент успешно изменен");
        getAndSetAccounts();
      })
      .catch((e) => toast.error(e.response?.data?.message))
      .finally(() => setLoading(false));

  };

  const acccountHandler = (val) => {
    setAccount(val);
    setLoading(true);

    updateOrder({
      id: order.id,
      account: val?.value,
    })
      .then((res) => {
        setOrder(res);
        toast.success("Подразделение успешно изменено");
      })
      .catch((e) => toast.error(e.response?.data?.message))
      .finally(() => setLoading(false));
  };

  const statusHandler = (status, order) => {
    setLoading(true);
    setStatus({
      orderId: order?.id,
      statusId: status?.id,
    })
      .then(() => {
        getAndSetOrder();
        toast.success("Статус успешно изменен");
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
      })
      .finally(() => setLoading(false));
  };

  const reserveHandler = () => {
    setLoading(true);
    createReserve({
      id,
    })
      .then(() => {
        getAndSetOrder();
        toast.success("Товар успешно зарезервирован");
      })
      .catch((e) => {
        toast.error(e.response.data.message);
      })
      .finally(() => setLoading(false));
  };


  const carryOutHandler = () => {
    if (!order.carriedOut) {
      for (const row of products) {
        const providerStock = row.providersStock.find(
          (val) => val.id === row.provider?.id
        );

        if (
          !providerStock &&
          row.provider?.id !== "518680fd-6d10-4543-96a4-108534ba69ca"
        ) {
          toast.error("Не все поставщики проставлены правильно");
          return;
        }
      }
    }

    if (!order.storeKeeper) {
      toast.error("Нужно выбрать сборщика");
      return;
    }

    setLoading(true);
    carryOutOrder({
      id,
      carryOut: order.carriedOut ? 0 : 1,
      shippingDate,
    })
      .then((res) => {
        if (res.carriedOut) {
          toast.success("Заказ успешно проведен");
          if (order.customer?.withDiadoc && !order.customer?.doNotSendToDiadoc) {
            diadocHandler();
          } else {
            getAndSetOrder();
          }
        } else {
          toast.success("Заказ успешно распроведен");
          getAndSetOrder();
        }

      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
        setLoading(false);
        getAndSetOrder();
      });
  };

  const sendTo1SHandler = () => {
    if (validProvider()) {
      toast.error("Не заполнены все поставщики в товарах");
      return;
    }

    setLoading(true);
    sendTo1S({ id })
      .then((res) => {
        if (res.status1S && res.status1S === 400) {
          toast.error("Ошибка отправки в 1С");
        } else {
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const storeKeeperHandler = (storeKeeper) => {
    setLoading(true);
    setStoreKeeper(storeKeeper);
    updateOrder({
      id: order.id,
      storeKeeper: storeKeeper?.value,
    })
      .then((res) => {
        setOrder(res);
        toast.success("Сборщик успешно установлен");
      })
      .catch((e) => toast.error(e.response?.data?.message))
      .finally(() => setLoading(false));
  };

  return (
    <Container fluid className="p-0 order">
      <Helmet>
        <title>
          Заказ №{`${order.number || ""} от ${order.customer?.name}`}
        </title>
      </Helmet>

      <HeaderPage title={`Заказ № ${order.number}`}>
        <>
          <div className="order__date-block">
            <Label>Дата</Label>
            <ButtonGroup>
              <InputMask
                id="date"
                name="date"
                type="text"
                onChange={changeHandler}
                value={date}
                mask={"99.99.9999"}
                className="form-control date"
              />
              <ChangeLogOrders id={id} />
            </ButtonGroup>
          </div>

          {(order.status?.sort === 5 || order.status?.sort === 6) && (
            <div className="order__carried-out-button">
              <div className="order__date-block shipping-date">
                <Label>Дата реализации</Label>
                <InputMask
                  id="shippingDate"
                  name="shippingDate"
                  type="text"
                  onChange={changeHandler}
                  value={shippingDate}
                  mask={"99.99.9999 99:99:99"}
                  className="form-control date"
                />
              </div>

              {order.carriedOut && <CheckCircle color="green" />}
              <Button
                color="primary"
                onClick={carryOutHandler}
                className="button-wide ml-1"
              >
                {order.carriedOut ? `Распровести заказ` : `Провести заказ`}
              </Button>
            </div>
          )}
        </>
      </HeaderPage>

      {loading && <MySpinner overlay />}

      {error && <Alert color="danger">{error}</Alert>}

      <Row className="elem">
        <div className="elem-field customer">
          <Label>Клиент</Label>
          <SelectCustomer customer={customer} setCustomer={customerHandler} />
        </div>
        {accounts.length > 1 &&
          <div className="elem-field">
            <Label>Подразделение</Label>
            <Select
              name="account"
              placeholder="Подразделение"
              className="react-select-container"
              classNamePrefix="react-select"
              options={accounts}
              value={account}
              onChange={(val) => acccountHandler(val)}
            />

          </div>
        }
        <div>
          <div className="balance">
            Баланс клиента:{" "}
            <b
              className={classNames({
                red: order.customer?.balance < 0,
                green: order.customer?.balance > 0,
              })}
            >
              {priceFormat(order.customer?.balance)} р
            </b>
          </div>
        </div>
      </Row>
      <Row className="elem">
        <div className="elem-field">
          <Label>Ответственный менеджер</Label>
          <Select
            name="admin"
            placeholder="Ответственный менеджер"
            className="react-select-container"
            classNamePrefix="react-select"
            options={admins}
            value={admin}
            onChange={selectHandler}
          />
        </div>
        <div className="elem-field">
          <Label>Сборщик</Label>
          <Select
            name="storeKeeper"
            placeholder="Сборщик"
            className="react-select-container"
            classNamePrefix="react-select"
            options={admins.filter((item) => item.department === "warehouse")}
            value={storeKeeper}
            onChange={(val) => storeKeeperHandler(val)}
          />
        </div>
        <div className="elem-field">
          <Label>Бонус</Label>
          <ButtonGroup>
            <Input
              name="bonus"
              placeholder="Бонус"
              onChange={changeHandler}
              value={bonus}
            />
            <Button onClick={setBonusHandler}>
              <Check size={15} />
            </Button>
          </ButtonGroup>
        </div>
        {order.customer?.type == 2 && (
          <>
            <div className="elem-field">
              <Label>Регл. учет</Label>
              <Checkbox
                name="regulated"
                value="regulated"
                checked={regulated}
                onChange={(e) => {
                  if (e.target.checked === false) {
                    if (
                      window.confirm(
                        "Если убрать галочку, то реализация не выгрузится в 1С!"
                      )
                    ) {
                      setRegulated(false);
                      return;
                    }
                    setRegulated(true);
                    return;
                  }
                  setRegulated(true);
                }}
              />
            </div>
            <div className="elem-field">
              <Label>Указать цену без НДС</Label>
              <Checkbox
                name="priceNds"
                value="priceNds"
                checked={priceNds}
                onChange={(e) => {
                  setPriceNds(() => e.target.checked);
                }}
              />
            </div>
          </>
        )}
      </Row>

      <DeliveryBlock
        order={order}
        setOrder={setOrder}
        setLoading={setLoading}
      />

      <Row className="elem">
        {order?.customer?.type === 1 && (
          <div className="elem-field">
            <Label>Способ оплаты</Label>
            <Select
              name="payment"
              placeholder="Способ оплаты"
              className="react-select-container"
              classNamePrefix="react-select"
              options={PAYMENTS}
              value={PAYMENTS.find((val) => payment === val.value)}
              onChange={(val) => paymentHandler(val)}
            />
          </div>
        )}

        <div className="elem-field contract">
          <Label>Договор</Label>
          <Select
            name="contract"
            placeholder="Договор"
            className="react-select-container"
            classNamePrefix="react-select"
            options={contracts}
            value={contract}
            onChange={selectHandler}
          />
        </div>
      </Row>

      <Row className="elem bottom">
        <div className="elem-field comment">
          <Label>Комментарий</Label>
          <Input
            type="textarea"
            name="comment"
            placeholder="Комментарий"
            onChange={changeHandler}
            value={comment}
          />
          <Button onClick={setCommentHandler} color="primary" className="mt-1">
            Сохранить комментарий
          </Button>
        </div>

        <div className="button-block">
          <Status order={order} statusHandler={statusHandler}>
            {order.status?.name}
          </Status>
          <SendInvoiceModal id={id} user={order.account} />

          {order.carriedOut &&
            <SendUtdModal id={id} order={order} user={order.account} />
          }

          {order.customer?.withDiadoc && order.carriedOut && (
            <Button
              color="primary"
              onClick={diadocHandler}
              className="button-wide"
            >
              {order.sentToDiadoc && (
                <CheckCircle color="green" className="sent-diadoc" />
              )}
              Выгрузить в Диадок
            </Button>
          )}

          {!noEdit && (
            <Button
              color="primary"
              onClick={sendTo1SHandler}
              className="button-wide"
            >
              Отправить в 1С
            </Button>
          )}

          {!noEdit && (
            <Button
              color="primary"
              onClick={reserveHandler}
              className="button-wide"
            >
              Резерв
            </Button>
          )}

          <UncontrolledButtonDropdown className="download">
            <DropdownToggle className="dropdown-toggle button-wide">
              Скачать
            </DropdownToggle>
            <DropdownMenu>
              {order.customer?.type === 2 ? (
                <>
                  <DropdownItem onClick={() => invoiceHandler(true)}>
                    Счет с печатью
                  </DropdownItem>
                  <DropdownItem onClick={() => invoiceHandler(false)}>
                    Счет без печати
                  </DropdownItem>
                </>
              ) : (
                <DropdownItem onClick={checkHandler}>Чек</DropdownItem>
              )}
              <DropdownItem>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`${baseUrl}/orderPicking.print?id=${id}&storeKeeper=${storeKeeper?.value || ""
                    }&admin=${user?.id || ""
                    }`}
                >
                  Сборочную ведомость
                </a>
              </DropdownItem>
              {order.carriedOut && (<>
                <DropdownItem onClick={updHandler}>УПД</DropdownItem>
                <DropdownItem onClick={getTorg1THandler}>ТТН</DropdownItem>
              </>
              )}

              <DropdownItem>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`${baseUrl}/orders.getOffer?id=${order.id}`}
                >
                  Комм. предложение
                </a>
              </DropdownItem>

              <DropdownItem onClick={() => setOpenModalLabel(true)}>
                Этикетки
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </div>

        {!noEdit && (
          <div className="button-block">
            <Button onClick={() => orderHandler()} color="primary button-wide">
              Сохранить
            </Button>
          </div>
        )}
      </Row>

      <OrderProductTable order={order} noEdit={noEdit} />

      <LabelModal
        id={id}
        openModal={openModalLabel}
        setOpenModal={setOpenModalLabel}
      />
    </Container>
  );
};
