import { MySpinner } from "components/MySpinner";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap";
import InputMask from "react-input-mask";
import { useHistory, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import { CheckCircle } from "react-feather";

import { createWriteOff, carryOutWriteOff } from "services/apiWarehouse";
import { dateFormat } from "utils/format";
import { HeaderPage } from "components/HeaderPage";
import { getWriteOffOne } from "services/apiWarehouse";
import { WriteOffProductTable } from "features/WriteOff/WriteOffProductTable";
import { WriteOffProductTableNew } from "features/WriteOff/WriteOffProductTableNew";

import "./writeOffOne.scss";

export const WriteOffOnePage = () => {
  const history = useHistory();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [isNew, setIsNew] = useState(!id ? true : false);
  const [number, setNumber] = useState("");
  const [createdAt, setCreatedAt] = useState("");
  const [comment, setComment] = useState("");
  const [admin, setAdmin] = useState({});
  const [carriedOut, setCarriedOut] = useState(false);
  const [products, setProducts] = useState([]);

  const getAndSetWriteOffOne = () => {
    getWriteOffOne(id)
      .then((res) => {
        console.log(res);
        setNumber(res.number);
        setProducts(res.products);
        setCreatedAt(res.createdAt);
        setComment(res.comment);
        setAdmin(res.admin);
        setCarriedOut(res.carriedOut);
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (!id) return;
    setLoading(true);
    getAndSetWriteOffOne();
  }, []);

  const createWriteOffHandler = () => {
    setLoading(true);
    createWriteOff({
      comment,
      products: products.filter((prod) => prod.count > 0),
    })
      .then((res) => {
        history.push(res.id);
        toast.success("Списание успешно создано");
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
      })
      .finally(() => setLoading(false));
  };

  const carryOutHandler = () => {
    setLoading(true);
    carryOutWriteOff({
      id,
      carryOut: !carriedOut,
    })
      .then((res) => {
        setCarriedOut(res.carriedOut);
        if (res.carriedOut) {
          toast.success("Списание успешно проведено");
        } else {
          toast.success("Списание успешно распроведено");
        }
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
      })
      .finally(() => setLoading(false));
  };

  const renderTitle = () => {
    if (isNew) {
      return `Новое списание`;
    }

    return `Списание № ${number || ""}`;
  };

  return (
    <Container fluid className="p-0 write-off-one">
      <Helmet>
        <title>{renderTitle()}</title>
      </Helmet>
      {loading && <MySpinner overlay />}

      <HeaderPage title={renderTitle()}>
        <>
          {!isNew && (
            <>
              <div className="write-off-one__date-block">
                <Label>Дата</Label>
                <InputMask
                  id="date"
                  name="date"
                  type="text"
                  onChange={() => {}}
                  value={dateFormat(createdAt)}
                  mask={"99.99.9999"}
                  className="form-control date"
                  disabled
                />
              </div>
              <div className="write-off-one__carried-out-button">
                {carriedOut && <CheckCircle color="green" />}

                <Button
                  color="primary"
                  onClick={carryOutHandler}
                  className="button-wide ml-1"
                >
                  {carriedOut ? `Распровести` : `Провести`}
                </Button>
              </div>
            </>
          )}
        </>
      </HeaderPage>

      <Card className="entering-balance-one__form mb-0">
        <CardBody className="pb-3">
          <Row>
            <Col md="4">
              <Label className="text-sm-right">Автор</Label>
            </Col>
            <Col md="4">
              <b>{admin.name}</b>
            </Col>
          </Row>

          <Row>
            <Col>
              <Label className="text-sm-right">Комментарий</Label>
              <Input
                type="textarea"
                name="comment"
                placeholder="Комментарий"
                value={comment || ""}
                onChange={(e) => setComment(e.target.value)}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
      {isNew ? (
        <WriteOffProductTableNew
          products={products}
          setProducts={setProducts}
        />
      ) : (
        <WriteOffProductTable
          setLoading={setLoading}
          products={products}
          setProducts={setProducts}
        />
      )}
      <Card>
        <CardBody>
          {isNew && (
            <Button
              color="primary"
              onClick={createWriteOffHandler}
              className="button-wide"
            >
              Создать
            </Button>
          )}
        </CardBody>
      </Card>
    </Container>
  );
};
