import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Link } from "react-router-dom";

import { Cut } from "components/Cut";

import { priceFormat } from "utils/format";

import "./analiticsSale.scss";

const FIELDS = {
  default: [
    "article",
    "brand",
    "name",
    "provider",
    "warehouse",
    "price",
    "count",
    "summ",
    "countCorr",
    "corr",
    "costPrice",
    "addExpenses",
    "margin",
    "countOrders",
    "inStock",
  ],
  nomenclature: [
    "article",
    "brand",
    "name",
    "price",
    "count",
    "summ",
    "countCorr",
    "corr",
    "costPrice",
    "addExpenses",
    "margin",
    "countOrders",
    "inStock",
  ],
  brands: [
    "brand",
    "price",
    "count",
    "summ",
    "countCorr",
    "corr",
    "costPrice",
    "addExpenses",
    "margin",
    "countOrders",
    "inStock",
  ],
  providers: [
    "provider",
    "count",
    "summ",
    "countCorr",
    "corr",
    "costPrice",
    "addExpenses",
    "margin",
    "countOrders",
    "inStock",
  ],
  warehouses: [
    "warehouse",
    "count",
    "summ",
    "countCorr",
    "corr",
    "costPrice",
    "addExpenses",
    "margin",
    "countOrders",
    "inStock",
  ],
  company: [
    "company",
    "count",
    "summ",
    "countCorr",
    "corr",
    "costPrice",
    "addExpenses",
    "margin",
    "countOrders",
    "inStock",
  ],
  customers: [
    "customer",
    "count",
    "summ",
    "countCorr",
    "corr",
    "costPrice",
    "addExpenses",
    "margin",
    "countOrders",
    "inStock",
  ],
  storeKeeper: [
    "storeKeeper",
    "count",
    "summ",
    "countCorr",
    "corr",
    "costPrice",
    "addExpenses",
    "margin",
    "countOrders",
  ],
  admin: [
    "admin",
    "count",
    "summ",
    "countCorr",
    "corr",
    "costPrice",
    "addExpenses",
    "margin",
    "countOrders",
  ],
  documents: [
    "number",
    "count",
    "summ",
    "countCorr",
    "corr",
    "costPrice",
    "addExpenses",
    "margin",
    "countOrders",
  ],
};

export const AnaliticsSaleTable = ({ analiticsSale, onSort, context }) => {
  const [columns, setColumns] = useState([]);

  const tableColumns = [
    {
      dataField: "company",
      text: "Подразделение",
      headerStyle: () => {
        return { width: "8%" };
      },
      editable: false,
    },
    {
      dataField: "number",
      text: "Заказ",
      formatter: (cell, row) => <a href={`/sales/orders/${row.id}`} target="_blank" rel="noreferrer">{cell}</a>,
      headerStyle: () => {
        return { width: "6%" };
      },
      editable: false,
    },
    {
      dataField: "customer",
      text: "Клиент",
      headerStyle: () => {
        return { width: "8%" };
      },
      editable: false,
    },
    {
      dataField: "storeKeeper",
      text: "Кладовщик",
      headerStyle: () => {
        return { width: "8%" };
      },
      editable: false,
    },
    {
      dataField: "admin",
      text: "Менеджер",
      headerStyle: () => {
        return { width: "8%" };
      },
      editable: false,
    },
    {
      dataField: "article",
      text: "Артикул",
      headerStyle: () => {
        return { width: "8%" };
      },
      formatter: (cell, row) => <Cut>{cell}</Cut>,
      editable: false,
    },
    {
      dataField: "brand",
      text: "Бренд",
      headerStyle: () => {
        return { width: "8%" };
      },
      editable: false,
    },
    {
      dataField: "name",
      text: "Наименование",
      headerStyle: () => {
        return { width: "18%" };
      },
      formatter: (cell, row) => <Cut>{cell}</Cut>,
      editable: false,
    },
    {
      dataField: "provider",
      text: "Пост-к",
      headerStyle: () => {
        return { width: "10%" };
      },
      formatter: (cell, row) => <Cut>{cell}</Cut>,
      editable: false,
    },
    {
      dataField: "warehouse",
      text: "Склад",
      headerStyle: () => {
        return { width: "6%" };
      },
      formatter: (cell, row) => <Cut>{cell}</Cut>,
      editable: false,
    },
    {
      dataField: "price",
      text: "Цена",
      headerStyle: () => {
        return { width: "6%" };
      },
      formatter: (cell, row) => priceFormat(cell),
      headerClasses: () => "right",
      classes: () => "right",
      editable: false,
    },
    {
      dataField: "count",
      text: "Кол-во",
      headerStyle: () => {
        return { width: "6%" };
      },
      headerClasses: () => "right",
      classes: () => "right",
      editable: false,
      sort: true,
      onSort,
      sortFunc: () => {
        return false;
      },
    },
    {
      dataField: "summ",
      text: "Сумма",
      headerStyle: () => {
        return { width: "6%" };
      },
      formatter: (cell, row) => priceFormat(cell),
      headerClasses: () => "right",
      classes: () => "right",
      editable: false,
      sort: true,
      onSort,
      sortFunc: () => {
        return false;
      },
    },
    {
      dataField: "countCorr",
      text: "Кол-во корр.",
      headerStyle: () => {
        return { width: "6%" };
      },
      headerClasses: () => "right",
      classes: () => "right",
      editable: false
    },
    {
      dataField: "corr",
      text: "Сумма корр.",
      headerStyle: () => {
        return { width: "6%" };
      },
      formatter: (cell, row) => priceFormat(cell),
      headerClasses: () => "right",
      classes: () => "right",
      editable: false
    },
    {
      dataField: "costPrice",
      text: "Себ-ть",
      headerStyle: () => {
        return { width: "6%" };
      },
      formatter: (cell, row) => priceFormat(cell),
      headerClasses: () => "right",
      classes: () => "right",
      editable: false,
    },
    {
      dataField: "addExpenses",
      text: "Доп расх",
      headerStyle: () => {
        return { width: "6%" };
      },
      formatter: (cell, row) => priceFormat(cell),
      headerClasses: () => "right",
      classes: (cell, row) => "right",
    },
    {
      dataField: "margin",
      text: "Маржа",
      headerStyle: () => {
        return { width: "6%" };
      },
      formatter: (cell, row) => <>{cell} %</>,
      headerClasses: () => "right",
      classes: (cell, row) => "right",
    },
    {
      dataField: "countOrders",
      text: "Кол-во док",
      headerStyle: () => {
        return { width: "6%" };
      },
      headerClasses: () => "right",
      classes: (cell, row) => "right",
    },
    {
      dataField: "inStock",
      text: "Остаток",
      headerStyle: () => {
        return { width: "6%" };
      },
      headerClasses: () => "right",
      classes: (cell, row) => "right",
    }
  ];

  useEffect(() => {
    const newColumns = tableColumns.filter((col) => {
      if (FIELDS[context?.value || "default"].includes(col.dataField))
        return col;
    });
    setColumns(newColumns);
  }, [context]);

  return (
    <>
      {columns.length && (
        <BootstrapTable
          keyField="id"
          data={analiticsSale}
          columns={columns}
          bootstrap4
          bordered={false}
          hover={true}
        />
      )}
    </>
  );
};
