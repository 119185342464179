import React, { useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import { useHistory, useLocation } from "react-router-dom";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import Select from "react-select";

import useQuery from "utils/useQuery";
import { MySpinner } from "components/MySpinner";
import { Pagination } from "components/Pagination";
import {
  downloadAnaliticsReceivables,
  generateAnaliticsReceivables,
  getAnaliticsReceivables,
  getProgressAnaliticsReceivables,
} from "services/apiAnalitics";
import { AnaliticsReceivablesTable } from "features/AnaliticsReceivables/AnaliticsReceivablesTable";
import { getAdmins, getAdminsForSelect } from "services/api";
import { SelectCustomer } from "features/Dashboard/SelectCustomer";
import { dateFormatU, dateTimeFormat, priceFormat } from "utils/format";

import "./analiticsReceivables.scss";
import { useUser } from "features/Users/UserContext";

registerLocale("ru", ru);

const SORT_FIELDS = [
  {
    label: "Дней просрочено ↑",
    value: "remainingDaysExpired_asc",
    by: "remainingDaysExpired",
    dir: "asc",
  },
  {
    label: "Дней просрочено ↓",
    value: "remainingDaysExpired_desc",
    by: "remainingDaysExpired",
    dir: "desc",
  },
];

export const AnaliticsReceivablesPage = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const query = useQuery(useLocation);
  const { admins } = useUser();
  const [analiticsReceivables, setAnaliticsReceivables] = useState([]);
  const [analiticsReceivablesCount, setAnaliticsReceivablesCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState({});
  const [loadingGenerate, setLoadingGenerate] = useState(false);
  const [isShowReset, setIsShowReset] = useState(false);
  const [lastUpdate, setLastUpdate] = useState(null);
  const [remainingDebt, setRemainingDebt] = useState(0);
  const [remainingDebtExpired, setRemainingDebtExpired] = useState(0);
  const [dateStart, setDateStart] = useState(
    query.get("dateStart") ? new Date(query.get("dateStart")) : ""
  );
  const [dateEnd, setDateEnd] = useState(
    query.get("dateEnd") ? new Date(query.get("dateEnd")) : ""
  );
  const [intervalProgress, setIntervalProgress] = useState(null);

  const sortByDefault = query.get("sortBy")
    ? SORT_FIELDS.find(
      (val) => val.value === `${query.get("sortBy")}_${query.get("sortDir")}`
    )
    : null;
  const [sort, setSort] = useState(sortByDefault);

  const [customer, setCustomer] = useState(
    query.get("customer")
      ? { label: query.get("customerName"), value: query.get("customer") }
      : null
  );

  const [admin, setAdmin] = useState(
    localStorage.getItem("filterAdmin")
      ? JSON.parse(localStorage.getItem("filterAdmin"))
      : null
  );

  const [currentPage, setCurrentPage] = useState(
    query.get("page") ? Number(query.get("page")) : 1
  );
  const getPageSize = localStorage.getItem("pageSize") ? JSON.parse(localStorage.getItem("pageSize")) : {};
  const [pageSize, setPageSize] = useState(getPageSize[pathname] || 10);

  const getAndSetAnaliticsReceivables = () => {
    getAnaliticsReceivables({
      paginationCount: pageSize,
      paginationOffset: (currentPage - 1) * pageSize,
      dateStart: dateFormatU(dateStart),
      dateEnd: dateFormatU(dateEnd),
      sort,
      admin: admin?.value,
      customer: customer?.value,
    })
      .then((res) => {
        setAnaliticsReceivables(res.items);
        setAnaliticsReceivablesCount(res.totalItems);
        setLastUpdate(res.dateLastUpdate);
        setRemainingDebt(res.totalRemainingDebt);
        setRemainingDebtExpired(res.totalRemainingDebtExpired);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setLoading(true);
    getAndSetAnaliticsReceivables();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize, admin?.value, customer?.value, sort]);

  useEffect(() => {
    const url = new URLSearchParams();
    if (currentPage !== 1) {
      url.append("page", currentPage);
    }

    if (dateStart) {
      url.append("dateStart", dateFormatU(dateStart));
    }

    if (dateEnd) {
      url.append("dateEnd", dateFormatU(dateEnd));
    }

    if (sort?.by && sort?.dir) {
      url.append("sortBy", sort.by);
      url.append("sortDir", sort.dir);
    }

    if (customer?.value) {
      url.append("customer", customer?.value);
      url.append("customerName", customer?.label);
    }

    if (url) {
      history.push(`${pathname}?${url}`);
    } else {
      history.push(pathname);
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize, dateStart, dateEnd, customer, sort]);

  const isSearch = () => {
    if (dateStart || dateEnd || sort || admin?.value || customer?.value) {
      return true;
    }
    return false;
  };

  const searchHandler = () => {
    if (!isSearch()) return;
    if (currentPage !== 1) {
      setCurrentPage(1);
      return;
    }
    setLoading(true);
    getAndSetAnaliticsReceivables();
  };

  const handleKeypress = (e) => {
    if (e.charCode === 13) {
      e.preventDefault();
      searchHandler();
    }
  };

  useEffect(() => {
    setIsShowReset(isSearch());
    if (!isSearch()) {
      setLoading(true);
      getAndSetAnaliticsReceivables();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateStart, dateEnd, customer?.value, admin?.value, sort]);

  const resetFilter = () => {
    setDateStart("");
    setDateEnd("");
    setCustomer(null);
    setAdmin(null);
    localStorage.removeItem("filterAdmin");
  };

  const getAndSetProgress = () => {
    getProgressAnaliticsReceivables().then((res) => {
      setProgress(res);

      if (typeof res?.progress !== "undefined") {
        setLoadingGenerate(true);
      } else {
        if (loadingGenerate) {
          toast.success("Генерация отчета завершена");
          setLoadingGenerate(false);
        }
      }
    });
  };

  useEffect(() => {
    getAndSetProgress();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const generateAnaliticsReceivablesHandler = () => {
    setLoadingGenerate(true);
    toast.success("Генерация отчета запущена");
    generateAnaliticsReceivables()
      .then((res) => { })
      .catch((e) => {
        toast.error(e.response?.data?.message);
      });
  };

  useEffect(() => {
    if (loadingGenerate) {
      setIntervalProgress(
        setInterval(() => {
          getAndSetProgress();
        }, 1000)
      );
    } else {
      clearInterval(intervalProgress);
      setIntervalProgress(null);
      setLoadingGenerate(false);
    }

    return () => {
      clearInterval(intervalProgress);
    };
  }, [loadingGenerate]);

  const selectHandler = (val, res) => {
    switch (res.name) {
      case "admin":
        localStorage.setItem("filterAdmin", JSON.stringify(val));
        setAdmin(val);
        break;
      default:
        break;
    }
  };

  const onSort = (field, order) => {
    setSort({
      by: field,
      dir: order,
    });
  };

  const downloadHandler = () => {
    setLoading(true);
    downloadAnaliticsReceivables({
      dateStart: dateFormatU(dateStart),
      dateEnd: dateFormatU(dateEnd),
      sort,
      admin: admin?.value,
      customer: customer?.value,
    })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Аналитика дебиторской задолженности.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Container fluid className="p-0 analitics-receivables">
      {loading && <MySpinner overlay />}
      <Card className="mb-0 filter">
        <CardBody>
          <h1 className="h3 mb-3">Аналитика дебиторской задолженности</h1>

          <Form className="mb-3">
            <Row>
              <Col md={3}>
                <Label>Период</Label>
                <InputGroup className="daterange">
                  <DatePicker
                    placeholderText="от"
                    selected={dateStart}
                    onChange={setDateStart}
                    selectsStart
                    startDate={dateStart}
                    endDate={dateEnd}
                    dateFormat="dd.MM.yyyy"
                    locale="ru"
                    className="form-control date-start"
                  />
                  <DatePicker
                    placeholderText="до"
                    selected={dateEnd}
                    onChange={setDateEnd}
                    selectsEnd
                    startDate={dateStart}
                    endDate={dateEnd}
                    minDate={dateStart}
                    dateFormat="dd.MM.yyyy"
                    locale="ru"
                    className="form-control date-end"
                  />
                </InputGroup>
              </Col>
              <Col md={3}>
                <Label>Менеджер</Label>
                <Select
                  name="admin"
                  placeholder="Ответственный менеджер"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={admins}
                  value={admin}
                  onChange={selectHandler}
                  isClearable
                />
              </Col>
              <Col md={3}>
                <Label>Клиент</Label>
                <SelectCustomer customer={customer} setCustomer={setCustomer} />
              </Col>

              <Col>
                <InputGroup className="with-label">
                  {isShowReset && (
                    <Button color="danger" onClick={() => resetFilter()}>
                      <FontAwesomeIcon icon={faWindowClose} />
                    </Button>
                  )}
                  <Button
                    color="primary"
                    onClick={searchHandler}
                    className="button-wide"
                  >
                    Поиск
                  </Button>
                </InputGroup>
              </Col>
            </Row>
          </Form>

          <Row>
            <Col md="3" className="d-flex flex-column align-items-start">
              <Button
                color="primary"
                className="button-wide btn btn-primary"
                onClick={generateAnaliticsReceivablesHandler}
                disabled={loadingGenerate}
              >
                Сгенерировать
                {loadingGenerate && (
                  <div className="spinner-wrapper">
                    <Spinner color="danger" size="sm" />
                  </div>
                )}
              </Button>
              {loadingGenerate && (
                <>Процесс генерации: {progress?.progress} %</>
              )}
            </Col>
            <Col md="3">
              <Button
                color="primary"
                className="button-wide btn btn-primary"
                onClick={downloadHandler}
              >
                Скачать
              </Button>
            </Col>
            <Col md="3">Дата обновления: {dateTimeFormat(lastUpdate)}</Col>
          </Row>
          <Row>
            <Col md="2">
              <Label className="text-sm-right">Остаток долга на сегодня</Label>
            </Col>
            <Col md="4">
              <b>{priceFormat(remainingDebt)} </b>
            </Col>
          </Row>
          <Row>
            <Col md="2">
              <Label className="text-sm-right">Сумма просрочки</Label>
            </Col>
            <Col md="4">
              <b style={{ color: "red" }}>{priceFormat(remainingDebtExpired)}</b>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <div>
        <AnaliticsReceivablesTable
          analiticsReceivables={analiticsReceivables}
          onSort={onSort}
        />

        <Card>
          <CardBody>
            <Pagination
              totalCount={analiticsReceivablesCount}
              currentPage={currentPage}
              pageSize={pageSize}
              setCurrentPage={setCurrentPage}
              onChangePageSize={setPageSize}
            />
          </CardBody>
        </Card>
      </div>
    </Container>
  );
};
