import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  Label,
  Row,
} from "reactstrap";
import { useHistory, useLocation } from "react-router-dom";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker, { registerLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";

import { MySpinner } from "components/MySpinner";
import { Pagination } from "components/Pagination";
import useQuery from "utils/useQuery";
import { getBalance } from "services/api";

import { tableColumns } from "./tableData";
import { SelectCustomer } from "features/Dashboard/SelectCustomer";
import { dateFormatU } from "utils/format";

registerLocale("ru", ru);

export const BalancePage = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const query = useQuery(useLocation);
  const [balance, setBalance] = useState([]);
  const [balanceCount, setBalanceCount] = useState(0);
  const [isShowReset, setIsShowReset] = useState(false);
  const [currentPage, setCurrentPage] = useState(
    query.get("page") ? Number(query.get("page")) : 1
  );
  const getPageSize = localStorage.getItem("pageSize") ? JSON.parse(localStorage.getItem("pageSize")) : {};
  const [pageSize, setPageSize] = useState(getPageSize[pathname] || 10);
  const [loading, setLoading] = useState(false);
  const [customer, setCustomer] = useState(
    query.get("customer")
      ? { label: query.get("customerName"), value: query.get("customer") }
      : null
  );
  const [dateStart, setDateStart] = useState(
    query.get("dateStart") ? new Date(query.get("dateStart")) : ""
  );
  const [dateEnd, setDateEnd] = useState(
    query.get("dateEnd") ? new Date(query.get("dateEnd")) : ""
  );

  const getAndSetBalance = () => {
    getBalance({
      paginationCount: pageSize,
      paginationOffset: (currentPage - 1) * pageSize,
      dateStart: dateFormatU(dateStart),
      dateEnd: dateFormatU(dateEnd),
      customer: customer?.value
    })
      .then((res) => {
        setBalance(res.items);
        setBalanceCount(res.totalItems);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    const url = new URLSearchParams();
    if (currentPage !== 1) {
      url.append("page", currentPage);
    }

    if (dateStart) {
      url.append("dateStart", dateFormatU(dateStart));
    }

    if (dateEnd) {
      url.append("dateEnd", dateFormatU(dateEnd));
    }

    if (customer?.value) {
      url.append("customer", customer?.value);
      url.append("customerName", customer?.label);
    }

    if (url) {
      history.push(`${pathname}?${url}`);
    } else {
      history.push(pathname);
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize, customer]);

  useEffect(() => {
    setLoading(true);
    getAndSetBalance();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize]);

  useEffect(() => {
    setIsShowReset(isSearch());
    if (!isSearch()) {
      setLoading(true);
      getAndSetBalance();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);

  const searchHandler = () => {
    if (currentPage !== 1) {
      setCurrentPage(1);
      return;
    }
    setLoading(true);
    getAndSetBalance();
  };

  const handleKeypress = (e) => {
    if (e.charCode === 13) {
      e.preventDefault();
      searchHandler();
    }
  };

  const isSearch = () => {
    if (customer || dateStart || dateEnd) {
      return true;
    }
    return false;
  };

  const resetFilter = () => {
    setCustomer(null);
    setDateStart(null);
    setDateEnd(null);
  };

  return (
    <Container fluid className="p-0 balance">
      {loading && <MySpinner overlay />}
      <Card className="mb-0">
        <CardBody>
          <h1 className="h3 mb-3">Взаиморасчеты</h1>

          <Form className="mb-3">
            <Row>
              {/* <Col lg="4">
                <InputGroup>
                  <Input
                    name="search"
                    placeholder="По бренду и синониму"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    onKeyPress={handleKeypress}
                  />
                  {isShowReset && (
                    <Button color="danger" onClick={() => resetFilter()}>
                      <FontAwesomeIcon icon={faWindowClose} />
                    </Button>
                  )}
                  <Button color="primary" onClick={searchHandler}>
                    Поиск
                  </Button>
                </InputGroup>
              </Col> */}

              <Col md={3}>
                <Label>Дата</Label>

                <InputGroup className="daterange">
                  <DatePicker
                    placeholderText="от"
                    selected={dateStart}
                    onChange={setDateStart}
                    selectsStart
                    startDate={dateStart}
                    endDate={dateEnd}
                    dateFormat="dd.MM.yyyy"
                    locale="ru"
                    className="form-control date-start"
                  />
                  <DatePicker
                    placeholderText="до"
                    selected={dateEnd}
                    onChange={setDateEnd}
                    selectsEnd
                    startDate={dateStart}
                    endDate={dateEnd}
                    minDate={dateStart}
                    dateFormat="dd.MM.yyyy"
                    locale="ru"
                    className="form-control date-end"
                  />
                </InputGroup>
              </Col>
              <Col md={3}>
                <Label>Клиент</Label>
                <SelectCustomer customer={customer} setCustomer={setCustomer} />
              </Col>
              <Col>
                <InputGroup className="with-label">
                  {isShowReset && (
                    <Button color="danger" onClick={() => resetFilter()}>
                      <FontAwesomeIcon icon={faWindowClose} />
                    </Button>
                  )}
                  <Button
                    color="primary"
                    onClick={searchHandler}
                    className="button-wide"
                  >
                    Поиск
                  </Button>
                </InputGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>

      <BootstrapTable
        keyField="id"
        data={balance}
        columns={tableColumns}
        bootstrap4
        bordered={false}
        hover={true}
      />

      <Card>
        <CardBody>
          <Pagination
            totalCount={balanceCount}
            currentPage={currentPage}
            pageSize={pageSize}
            setCurrentPage={setCurrentPage}
            onChangePageSize={setPageSize}
          />
        </CardBody>
      </Card>
    </Container>
  );

}