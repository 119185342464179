import {
  Clipboard as ClipboardIcon
} from "react-feather";

import { CorrOrderOnePage } from "pages/corrOrderOne/CorrOrderOne";
import { CorrOrdersPage } from "pages/corrOrders/CorrOrders";
import { UsersPage } from "pages/users";
import { DeliveryListPage } from "pages/deliveryList";
import { Markups } from "pages/markups";
import { OrderOnePage } from "pages/orderOne";
import { OrdersPage } from "pages/orders";
import { BalancePage } from "pages/balance";
import { UserOnePage } from "pages/userOne";
import { MarkupOne } from "pages/markupOne";
import { SendingPriceListPage } from "pages/sendingPriceList";
import { SendingPriceListOnePage } from "pages/sendingPriceListOne/SendingPriceListOne";

const usersRoutes = {
  path: "/sales/users",
  name: "Клиенты",
  title: "Клиенты",
  component: UsersPage,
};

const userNewRoutes = {
  path: "/sales/users/new",
  name: "Новый клиент",
  title: "Новый клиент",
  component: UserOnePage,
};

const userOneRoutes = {
  path: "/sales/users/:id",
  name: "Клиент",
  component: UserOnePage,
};


const corrOrdersRoutes = {
  path: "/sales/corrOrders",
  name: "Корр. реализаций",
  title: "Корректировки реализаций",
  component: CorrOrdersPage,
};

const corrOrderNewRoutes = {
  path: "/sales/corrOrders/new",
  name: "Новая корректировка реализации",
  title: "Новая корректировка реализации",
  component: CorrOrderOnePage,
};

const corrOrderOneRoutes = {
  path: "/sales/corrOrders/:id",
  name: "Корректировка реализации",
  title: "Корректировка реализации",
  component: CorrOrderOnePage,
};

const ordersRoutes = {
  path: "/sales/orders",
  name: "Заказы",
  title: "Заказы",
  component: OrdersPage,
};

const orderOneRoutes = {
  path: "/sales/orders/:id",
  name: "Заказ",
  component: OrderOnePage,
};

const deliveryListRoutes = {
  path: "/sales/deliveryList",
  name: "Список доставок",
  title: "Список доставок",
  component: DeliveryListPage,
};

const markupsRoutes = {
  path: "/sales/markups",
  name: "Наценки",
  title: "Наценки",
  component: Markups,
};

const markupNewRoutes = {
  path: "/sales/markups/new",
  name: "Новый шаблон наценки",
  title: "Новый шаблон наценки",
  component: MarkupOne,
};

const markupOneRoutes = {
  path: "/sales/markups/:id",
  name: "Шаблон наценки",
  title: "Шаблон наценки",
  component: MarkupOne,
};

const balancesRoutes = {
  path: "/sales/balance",
  name: "Взаиморасчеты",
  title: "Взаиморасчеты",
  component: BalancePage,
};

const sendingPriceListRoutes = {
  path: "/sales/sendingPriceList",
  name: "Рассылки прайс листов",
  title: "Рассылки прайс листов",
  component: SendingPriceListPage,
};

const sendingPriceListNewRoutes = {
  path: "/sales/sendingPriceList/new",
  name: "Новая рассылка прайс листов",
  title: "Новая рассылка прайс листов",
  component: SendingPriceListOnePage,
};

const sendingPriceListOneRoutes = {
  path: "/sales/sendingPriceList/:id",
  name: "Рассылка прайс листов",
  title: "Рассылка прайс листов",
  component: SendingPriceListOnePage,
};

export const salesMenu = {
  path: "/sales",
  name: "Продажи",
  title: "Продажи",
  icon: ClipboardIcon,
  children: [
    ordersRoutes,
    corrOrdersRoutes,
    usersRoutes,
    deliveryListRoutes,
    markupsRoutes,
    balancesRoutes,
    sendingPriceListRoutes
  ],
};

export const salesRoutes = [
  ordersRoutes,
  corrOrdersRoutes,
  corrOrderNewRoutes,
  corrOrderOneRoutes,
  usersRoutes,
  deliveryListRoutes,
  orderOneRoutes,
  userNewRoutes,
  userOneRoutes,
  markupsRoutes,
  markupNewRoutes,
  markupOneRoutes,
  balancesRoutes,
  sendingPriceListRoutes,
  sendingPriceListNewRoutes,
  sendingPriceListOneRoutes
];
