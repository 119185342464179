import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import BootstrapTable from "react-bootstrap-table-next";
import { dateTimeFormat } from "utils/format";
import { TableButton } from "components/TableButton";
import { Info } from "react-feather";
import { GetRelatedDocumentsModal } from "../GetRelatedDocumentsModal";
import { ResetDocumentsModal } from "../ResetDocumentsModal/ResetDocumentsModal";

const DOCUMENTS = {
  order: "sales/orders",
  reserve: "sales/orders",
  stockReserve: "sales/orders",
  supply: "receipt/supply",
  corrOrder: "sales/corrOrders",
  returnToProvider: "receipt/returnsToProvider",
  writeOff: "stock/writeOffs",
  enteringBalance: "stock/enteringBalance",
  moving: "stock/moving",
  misgrading: "stock/misgradings",
  acceptanceAct: "receipt/acceptanceActs",
};

export const StockTable = ({
  stock,
  nomenclature,
  getAndSetStock,
  warehouse,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [openModalResetDocuments, setOpenModalResetDocuments] = useState(false);
  const [stockId, setStockId] = useState(null);
  const [row, setRow] = useState({});
  const getRelatedDocuments = (row) => {
    setRow(row);
    setOpenModal(true);
  };

  const tableColumns = [
    {
      dataField: "document",
      text: "Документ",
      headerStyle: () => {
        return { width: "12%" };
      },
      formatter: (cell) =>
        cell && (
          <Link to={`/${DOCUMENTS[cell.document]}/${cell.id}`} target="_blank">
            {cell.name}
          </Link>
        ),
    },
    {
      dataField: "",
      text: "",
      headerStyle: () => {
        return { width: "5%" };
      },
      formatter: (cell, row) => (
        <TableButton Icon={Info} onClick={() => getRelatedDocuments(row)} />
      ),
    },
    {
      dataField: "date",
      text: "Дата",
      headerStyle: () => {
        return { width: "12%" };
      },
      formatter: (cell) => dateTimeFormat(cell),
    },
    {
      dataField: "warehouse",
      text: "Склад",
      headerStyle: () => {
        return { width: "7%" };
      },
    },
    {
      dataField: "inStock",
      text: "Наличие",
      headerStyle: () => {
        return { width: "7%" };
      },
      headerClasses: () => "right",
      classes: () => "right bold",
      formatter: (cell) =>
        cell ? (
          <span className={classNames({ red: cell < 0, green: cell > 0 })}>
            {cell}
          </span>
        ) : (
          ""
        ),
    },
    {
      dataField: "inReserve",
      text: "Резерв",
      headerStyle: () => {
        return { width: "7%" };
      },
      headerClasses: () => "right",
      classes: () => "right bold",
      formatter: (cell) =>
        cell ? (
          <span className={classNames({ red: cell < 0, green: cell > 0 })}>
            {cell}
          </span>
        ) : (
          ""
        ),
    },
    {
      dataField: "",
      text: "",
      headerStyle: () => {
        return { width: "30%" };
      },
    },
  ];

  return (
    <>
      <BootstrapTable
        wrapperClasses="stock-table"
        keyField="id"
        data={stock}
        columns={tableColumns}
        bootstrap4
        bordered={false}
        hover={true}
      />

      <GetRelatedDocumentsModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        row={row}
        setStockId={setStockId}
        setOpenModalResetDocuments={setOpenModalResetDocuments}
        warehouse={warehouse}
        nomenclature={nomenclature}
      />

      <ResetDocumentsModal
        openModal={openModalResetDocuments}
        setOpenModal={setOpenModalResetDocuments}
        stockId={stockId}
        nomenclature={nomenclature}
        getAndSetStock={getAndSetStock}
        warehouse={warehouse}
      />
    </>
  );
};
