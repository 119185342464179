import { CloseButton } from "components/CloseButton";
import { MySpinner } from "components/MySpinner";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Button,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { sendInvoice } from "services/api";

export const SendInvoiceModal = ({ id, user }) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const invoiceSendHandler = () => {
    setLoading(true);
    sendInvoice({ id })
      .then((res) => {
        toast.success("Счет успешно отправлен");
      })
      .catch(() => {
        toast.error("Ошибка при отправке счета. Возможно один из адресов некорректен.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Button color="primary" onClick={() => setOpen(true)} className="button-wide">
        Отправить счет
      </Button>

      <Modal isOpen={open} toggle={() => setOpen(false)}>
        <ModalHeader>
          <div className="modal-title-text">Отправить счет на email</div>
          <CloseButton onClick={() => setOpen(false)} />
        </ModalHeader>
        <ModalBody className="m-3">
          {loading && <MySpinner overlay />}

          <p>Счет будет отправлен на Email:</p>
          <ul>
            {user?.emailsForDocuments?.map(email => <li key={email}>{email}</li>)}
          </ul>

          <div className="d-flex justify-content-between">
            <Button onClick={() => setOpen(false)} color="secondary">
              Закрыть
            </Button>
            <Button onClick={invoiceSendHandler} color="primary">
              Отправить
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};
