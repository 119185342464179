import react, { useEffect, useState } from "react";
import { useLocation, useHistory, Link } from "react-router-dom";
import { Button, Card, CardBody, Col, Container, Form, Input, InputGroup, Label, Row } from "reactstrap";
import Select from "react-select";

import { MySpinner } from "components/MySpinner";
import { AvitoTable } from "features/Avito/AvitoTable";
import useQuery from "utils/useQuery";
import { bulkAvitoUpdate, getAvitoAds } from "services/api";
import { Pagination } from "components/Pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { Checkbox } from "components/Checkbox";
import { TECHNIC_TYPES, TYPES } from "./types";

export const AvitoPage = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const query = useQuery(useLocation);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(
    query.get("page") ? Number(query.get("page")) : 1
  );
  const getPageSize = localStorage.getItem("pageSize") ? JSON.parse(localStorage.getItem("pageSize")) : {};
  const [pageSize, setPageSize] = useState(getPageSize[pathname] || 10);
  const [ads, setAds] = useState([]);
  const [adsCount, setAdsCount] = useState(0);
  const [isShowReset, setIsShowReset] = useState(false);
  const [search, setSearch] = useState(query.get("search") || "");
  const [active, setActive] = useState(query.get("active") || false);
  const [selected, setSelected] = useState([]);
  const [type, setType] = useState(null);
  const [technicTypes, setTechnicTypes] = useState([]);
  const [technicType, setTechnicType] = useState(null);

  const getAndSetAvitoAds = () => {
    getAvitoAds({
      paginationCount: pageSize,
      paginationOffset: (currentPage - 1) * pageSize,
      search,
      active: active || null
    })
      .then((res) => {
        setAds(res.items);
        setAdsCount(res.totalItems);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setLoading(true);
    getAndSetAvitoAds();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize]);


  useEffect(() => {
    const url = new URLSearchParams();
    if (currentPage !== 1) {
      url.append("page", currentPage);
    }

    if (search) {
      url.append("search", search);
    }

    if (active) {
      url.append("active", active);
    }

    if (url) {
      history.push(`${pathname}?${url}`);
    } else {
      history.push(pathname);
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPage,
    pageSize,
    search,
    active
  ]);

  const isSearch = () => {
    if (
      search || active
    ) {
      return true;
    }
    return false;
  };

  const searchHandler = () => {
    if (!isSearch()) return;
    if (currentPage !== 1) {
      setCurrentPage(1);
      return;
    }
    setLoading(true);
    getAndSetAvitoAds();
  };

  const handleKeypress = (e) => {
    if (e.charCode === 13) {
      e.preventDefault();
      searchHandler();
    }
  };

  useEffect(() => {
    setIsShowReset(isSearch());
    if (!isSearch()) {
      setLoading(true);
      getAndSetAvitoAds();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    search, active
  ]);

  const resetFilter = () => {
    setSearch("");
    setActive(false);
  };

  const selectType = (val) => {
    setType(val);

    setTechnicType(null);

    const technicTypesTmp = TECHNIC_TYPES[val?.value];

    if (technicTypesTmp) {
      setTechnicTypes(technicTypesTmp.map(val => ({ label: val, value: val })));
    }
  };

  const bulkUpdateHandler = () => {
    if (!selected.length) return;
    if (!type || (type && (technicTypes?.length > 0 && !technicType))) return;
    bulkAvitoUpdate({
      ids: selected,
      sparePartType: [type, technicType]
    })
      .then((res) => {
        getAndSetAvitoAds();
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  return <Container fluid className="p-0 avito">
    {loading && <MySpinner overlay />}
    <Card className="mb-0 filter">
      <CardBody>
        <h1 className="h3 mb-3">Авито</h1>
        <Form className="mb-3">

          <Row>
            <Col>
              <Label>Артикул</Label>
              <Input
                name="search"
                placeholder="По артикулу"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyUp={handleKeypress}
              />
            </Col>
            <Col md={1}>
              <Label>Активные</Label>
              <Checkbox
                name="active"
                onChange={(e) => setActive(e.target.checked)}
                checked={active}
              />
            </Col>
            <Col md={2}>
              <InputGroup className="with-label">
                {isShowReset && (
                  <Button color="danger" onClick={() => resetFilter()}>
                    <FontAwesomeIcon icon={faWindowClose} />
                  </Button>
                )}
                <Button
                  color="primary"
                  onClick={searchHandler}
                  className="button-wide"
                >
                  Поиск
                </Button>
              </InputGroup>
            </Col>
          </Row>
        </Form>
        <Row className="mb-2">
          <Col md="2">
            <Link
              to="/reference/avito/new"
              color="primary"
              className="button-wide btn btn-primary"
            >
              Добавить объявление
            </Link>
          </Col>
        </Row>

        {selected.length > 0 &&
          <div className=" mt-4">
            <Label>Выберите вид и тип товара</Label>
            <Row className="mb-2">
              <Col md="3">
                <Select
                  name="sparePartType"
                  placeholder="Вид детали"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={TYPES.map(val => ({ label: val, value: val }))}
                  value={type}
                  onChange={(val) => selectType(val)}
                />
              </Col>
              {technicTypes?.length > 0 &&
                <Col md="3">
                  <Select
                    name="technicSparePartType"
                    placeholder="Тип детали"
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={technicTypes}
                    value={technicType}
                    onChange={setTechnicType}
                  />
                </Col>
              }

              <Button
                color="primary"
                onClick={bulkUpdateHandler}
                className="button-wide"
                type="button"
                disabled={!type || (type && (technicTypes?.length > 0 && !technicType))}
              >
                Сохранить
              </Button>

            </Row>
          </div>
        }

      </CardBody>
    </Card>
    <div>

      <AvitoTable ads={ads} setSelected={setSelected} />

      <Card>
        <CardBody>
          <Pagination
            totalCount={adsCount}
            currentPage={currentPage}
            pageSize={pageSize}
            setCurrentPage={setCurrentPage}
            onChangePageSize={setPageSize}
          />
        </CardBody>
      </Card>
    </div>
  </Container>
}