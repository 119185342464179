import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { Button, ButtonGroup, Input, Spinner } from "reactstrap";

import { suggestionNomenclatrue } from "services/api";

import "./autocompleteArticle.scss";

export const AutocompleteArticle = ({
  searchButton,
  article,
  brand,
  setArticle,
  setBrand,
  setName,
  setPrice,
  setProvider,
  setNomenclature,
  getAndSetPrice,
  needValid,
  errors,
  style = null,
  size,
  func,
}) => {
  const autocompleteEl = useRef(null);
  const [search, setSearch] = useState(article || "");
  const [shown, setShown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [typingTimeout, setTypingTimeout] = useState(0);

  useEffect(() => {
    if (!article) return;
    setSearch(article.trim());
  }, [article]);

  const suggestionConvert = (res) => {
    return Object.entries(res).map(([brand, value]) => {
      return {
        id: `${brand}_${value.partNumber}`,
        article: value.partNumber,
        brand: {
          id: value.brand,
          name: value.brand,
        },
        name: value.name,
      };
    });
  };

  const autocompleteHandler = (e) => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setSearch(e.target.value.trim());

    if (e.target.value.length < 2) {
      setSuggestions([]);
      setShown(false);
      return;
    }

    setTypingTimeout(
      setTimeout(() => {
        setLoading(true);
        if (func) {
          func({ article: e.target.value })
            .then((res) => {
              setSuggestions(suggestionConvert(res));
              setShown(true);
            })
            .finally(() => setLoading(false));
        } else {
          suggestionNomenclatrue({ article: e.target.value })
            .then((res) => {
              setSuggestions(res);
              setShown(true);
            })
            .finally(() => setLoading(false));
        }
      }, 600)
    );
  };

  const selectHandler = (item) => {
    setSearch(item.article);
    setArticle(item.article);
    if (setBrand) {
      setBrand(item.brand);
    }

    if (setNomenclature) {
      setNomenclature(item.id);
    }

    if (setName) {
      setName(item.name);
    }
    if (setProvider) {
      setProvider(item.provider);
    }
    if (setPrice) {
      setPrice(item.price);
    }
    setShown(false);
  };

  const focusHandler = () => {
    if (!suggestions.length) return;
    setShown(true);
  };

  useEffect(() => {
    const closeResult = (e) => {
      if (
        !autocompleteEl.current ||
        !autocompleteEl.current.contains(e.target)
      ) {
        setShown(false);
      }
    };
    document.addEventListener("click", closeResult);
    return () => document.removeEventListener("click", closeResult);
  }, []);

  const onEnterPressHandler = (e) => {
    if (e.charCode === 13) {
      e.preventDefault();
      setArticle(e.target.value);
      setBrand("");
      setSuggestions([]);
      getAndSetPrice(article, brand);
    }
  };

  const onButtonSearchHandler = () => {
    setArticle(search);
    setBrand("");
    setSuggestions([]);
    getAndSetPrice(search, "");
  };

  const inputRender = () => {
    if (size) {
      return (
        <div className="autocomplete__input-big">
          <Input
            name="article"
            value={search}
            onChange={autocompleteHandler}
            onFocus={focusHandler}
            onKeyPress={onEnterPressHandler}
            placeholder="Введите артикул"
            invalid={needValid && errors.hasOwnProperty("article")}
          />
          <Button
            onClick={onButtonSearchHandler}
            className="autocomplete__button"
            color="third"
          >
            Поиск
          </Button>
        </div>
      );
    }
    return (
      <ButtonGroup style={{ width: "100%" }}>
        <Input
          name="article"
          value={search}
          onChange={autocompleteHandler}
          onFocus={focusHandler}
          onKeyPress={onEnterPressHandler}
          placeholder="Введите артикул"
          invalid={needValid && errors.hasOwnProperty("article")}
        />
        {searchButton && (
          <Button onClick={onButtonSearchHandler} color="primary">
            Поиск
          </Button>
        )}
      </ButtonGroup>
    );
  };

  return (
    <div className="autocomplete" ref={autocompleteEl} style={style}>
      {inputRender()}
      {loading && (
        <Spinner
          color="danger"
          style={{ width: "1rem", height: "1rem" }}
          className={classNames("mr-6 autocomplete__spinner", {
            search: searchButton,
          })}
        />
      )}
      {shown && suggestions.length > 0 && (
        <div className="autocomplete__result-list-wrapper">
          {suggestions.map((item) => (
            <div
              className="autocomplete__result-list-item"
              key={item.id}
              onClick={() => selectHandler(item)}
              dangerouslySetInnerHTML={{
                __html:
                  item.article.replace(search, `<strong>${search}</strong>`) +
                  " " +
                  item.brand?.name,
              }}
            />
          ))}
        </div>
      )}
    </div>
  );
};
