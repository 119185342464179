import React, { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Barcode from "react-barcode";
import { Button } from "reactstrap";

import { getNomenclatureOne } from "services/api";
import { MySpinner } from "components/MySpinner";

import "./printBarcode.scss";

export const PrintBarcodePage = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [article, setArticle] = useState("");
  const [brand, setBrand] = useState("");
  const [name, setName] = useState("");
  const [barcode, setBarcode] = useState("");

  const getAndSetNomenclatureOne = () => {
    getNomenclatureOne(id)
      .then((res) => {
        setArticle(res.article);
        setName(res.name);
        setBrand(res.brand?.name);
        setBarcode(res.barcode);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (!id) return;
    getAndSetNomenclatureOne();
  }, [id]);

  return (
    <>
      {loading && <MySpinner overlay />}
      <div className="print barcodePrint">
        <div className="barcodePrint__article">{article}</div>
        <div className="barcodePrint__brand">{brand}</div>
        <div className="barcodePrint__name">{name}</div>
        <div>
          <Barcode value={barcode} format="EAN13" />
        </div>
      </div>
      <Button href="javascript:void(print())">Печатать</Button>
    </>
  );
};
