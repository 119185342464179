import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { dateFormat } from "utils/format";
import { CheckCircle } from "react-feather";
import { Cut } from "components/Cut";

import { SelectDriver } from "../SelectDriver";

import "./deliveryListTable.scss";
import { updateDelivery, updateOrder } from "services/api";
import { Button, Card, CardBody } from "reactstrap";
import { useUser } from "features/Users/UserContext";
import { toast } from "react-toastify";

export const DeliveryListTable = ({
  setLoading,
  deliveryList,
  getAndSetDeliveryList,
}) => {
  const { admins } = useUser();
  const [expanded, setExpanded] = useState([]);

  useEffect(() => {
    setExpanded(deliveryList.map((item) => item.id));
  }, [deliveryList]);

  const driverHandler = (id, driver) => {
    if (!driver) return;
    setLoading(true);
    updateDelivery({
      id,
      driver,
    })
      .then((res) => {
        getAndSetDeliveryList();
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
        setLoading(false);
      });
  };

  const deliveryHandler = (id) => {
    setLoading(true);
    updateOrder({
      id,
      delivered: true,
    })
      .then(() => {
        getAndSetDeliveryList();
        toast.success("Статус доставки успешно сохранен");
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
        setLoading(false);
      });
  };

  const tableColumns = [
    {
      dataField: "deliveryType",
      text: "Тип дост.",
      formatter: (cell, row) => <Cut>{cell}</Cut>,
      headerStyle: () => {
        return { width: "8%" };
      },
    },
    {
      dataField: "transportCompany",
      text: "ТК",
      formatter: (cell, row) => <Cut>{cell}</Cut>,
      headerStyle: () => {
        return { width: "6%" };
      },
    },
    {
      dataField: "address",
      text: "Адрес",
      formatter: (cell, row) => <Cut>{cell}</Cut>,
      headerStyle: () => {
        return { width: "14%" };
      },
    },
    {
      dataField: "date",
      text: "Дата дост.",
      formatter: (cell, row) => dateFormat(cell),
      headerStyle: () => {
        return { width: "6%" };
      },
    },
    {
      dataField: "customer",
      text: "Клиент",
      formatter: (cell) => <Cut>{cell}</Cut>,
      headerStyle: () => {
        return { width: "10%" };
      },
    },
    {
      dataField: "driver",
      text: "Водитель",
      headerStyle: () => {
        return { width: "7%" };
      },
      formatter: (cell, row) => (
        <SelectDriver row={row} admins={admins} driverHandler={driverHandler} />
      ),
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          e.stopPropagation();
        },
      },
    },
    {
      dataField: "number",
      text: "№",
      formatter: (cell, row) => (
        <a href={`/sales/orders/${row.orderId}`} rel="no-referer">
          {cell}
        </a>
      ),
      headerStyle: () => {
        return { width: "3%" };
      },
    },
    {
      dataField: "status",
      text: "Статус",
      headerStyle: () => {
        return { width: "4%" };
      },
    },
    {
      dataField: "delivery",
      text: "Отгрузка",
      headerStyle: () => {
        return { width: "6%" };
      },
    },
    {
      dataField: "placeQuantity",
      text: "Мест",
      headerStyle: () => {
        return { width: "4%" };
      },
    },
    {
      dataField: "payer",
      text: "Плательщик",
      headerStyle: () => {
        return { width: "8%" };
      },
    },
    {
      dataField: "contact",
      text: "Контакты",
      headerStyle: () => {
        return { width: "13%" };
      },
    },
    {
      dataField: "admin",
      text: "Менеджер",
      headerStyle: () => {
        return { width: "8%" };
      },
    },
    {
      dataField: "comment",
      text: "Комментарий",
      headerStyle: () => {
        return { width: "16%" };
      },
    },
  ];

  const expandHandler = () => {
    if (expanded.length === 0) {
      setExpanded(deliveryList.map((item) => item.id));
    } else {
      setExpanded([]);
    }
  };

  const expandRow = {
    renderer: (row) => {
      return (
        <div className="react-bootstrap-table">
          <table className="table table-hover">
            <tbody>
              {row.orders?.map((item) => (
                <tr key={item.id}>
                  <td style={{ width: "51%" }}></td>
                  <td style={{ width: "3%" }}>
                    <a
                      href={`/sales/orders/${item.id}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {item.number}
                    </a>
                  </td>
                  <td style={{ width: "4%" }}>
                    <div
                      className="deliveryListStatus"
                      style={{ backgroundColor: item.statusColor }}
                    >
                      {item.status}
                    </div>
                  </td>
                  <td style={{ width: "6%", textAlign: "center" }}>
                    {item.delivered ? (
                      <CheckCircle color="green" />
                    ) : (
                      <>
                        {item.status === "Продано" &&
                          <button
                            className="btn btn-primary"
                            onClick={() => deliveryHandler(item.id)}
                          >
                            Отгрузить
                          </button>
                        }
                      </>
                    )}
                  </td>
                  <td style={{ width: "4%" }}>{item.placeQuantity}</td>
                  <td style={{ width: "8%" }}>
                    <Cut>{item.payer}</Cut>
                  </td>
                  <td style={{ width: "13%" }}>
                    <Cut>{item.contact}</Cut>
                  </td>
                  <td style={{ width: "8%" }}>
                    <Cut>{item.admin}</Cut>
                  </td>
                  <td style={{ width: "16%" }}>
                    <Cut>{item.comment}</Cut>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    },
    expanded: expanded,
    expandByColumnOnly: true,
  };

  return (
    <>
      <Card className="mb-2">
        <CardBody className="pt-0 pb-0">
          <Button onClick={expandHandler}>
            {expanded.length === 0 ? "Развернуть" : "Свернуть"}
          </Button>
        </CardBody>
      </Card>
      <BootstrapTable
        keyField="id"
        data={deliveryList}
        columns={tableColumns}
        bootstrap4
        bordered={false}
        hover={true}
        classes="pointer delivery-list"
        expandRow={expandRow}
        rowClasses="mainRow"
      />
    </>
  );
};
