import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";

import "./selectDriver.scss";

export const SelectDriver = ({ row, admins, driverHandler }) => {
  const [driver, setDriver] = useState(null);
  const [shown, setShown] = useState(false);
  const rootEl = useRef(null);

  useEffect(() => {
    setShown(false);
    driverHandler(row.id, driver?.value);
  }, [driver]);

  useEffect(() => {
    const onClick = (e) =>
      rootEl.current?.contains(e.target) || setShown(false);
    document.addEventListener("click", onClick);
    return () => document.removeEventListener("click", onClick);
  }, []);

  return (
    <>
      <span onClick={() => setShown(true)}>
        {row.driver || "Выбрать водителя"}
      </span>
      {shown && (
        <div ref={rootEl}>
          <Select
            name="driver"
            placeholder="Выберите водителя"
            className="react-select-container select-driver"
            classNamePrefix="react-select"
            options={admins.filter((item) => item.department === "drivers")}
            isSearchable
            value={driver}
            onChange={setDriver}
          />
        </div>
      )}
    </>
  );
};
