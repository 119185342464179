import React, { useEffect, useState } from "react";

import "./checkbox.scss";
import classNames from "classnames";

export const Checkbox = ({
  id,
  children,
  value = "on",
  name = "",
  checked = false,
  onChange,
  disabled,
  invalid
}) => {
  const [active, setActive] = useState(checked);
  useEffect(() => setActive(checked), [checked]);

  const changeHandler = (e) => {
    setActive(e.target.checked);
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <div className={classNames(
      "custom-checkbox",
      {
        "is-invalid": invalid
      })} >
      <input
        type="checkbox"
        id={`checkbox_${id || name}`}
        name={name}
        value={"on"}
        onChange={changeHandler}
        checked={active}
        disabled={disabled}
      />
      <label htmlFor={`checkbox_${id || name}`} />
    </div >
  );
};
