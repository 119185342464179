import { MySpinner } from "components/MySpinner";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import { useHistory, useLocation } from "react-router-dom";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import Select from "react-select";

import useQuery from "utils/useQuery";
import { Pagination } from "components/Pagination";
import {
  downloadAnaliticsStock,
  generateAnaliticsStock,
  getAnaliticsStock,
  getProgress,
} from "services/apiAnalitics";
import { AnaliticsStockTable } from "features/AnaliticsStock/AnaliticsStockTable";
import { SelectSearch } from "components/SelectSearch";
import { brandsSuggestion } from "services/api";
import { getWarehouses } from "services/apiWarehouse";

import "./analiticsStock.scss";
import { dateTimeFormat } from "utils/format";

const CONTEXT = [
  { label: "По номенклатуре", value: "nomenclature" },
  { label: "По брендам", value: "brands" },
  { label: "По поставщикам", value: "providers" },
  { label: "По складам", value: "warehouses" },
  { label: "По подразделениям", value: "company" },
];

export const AnaliticsStockPage = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const query = useQuery(useLocation);
  const [analiticsStock, setAnaliticsStock] = useState([]);
  const [analiticsStockCount, setAnaliticsStockCount] = useState(0);
  const [warehouses, setWarehouses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState({});
  const [loadingGenerate, setLoadingGenerate] = useState(false);
  const [search, setSearch] = useState(query.get("search") || "");
  const [isShowReset, setIsShowReset] = useState(false);
  const [priceStart, setPriceStart] = useState("");
  const [priceEnd, setPriceEnd] = useState("");
  const [intervalBalance, setIntervalBalance] = useState(null);
  const [context, setContext] = useState(
    query.get("context")
      ? { label: query.get("contextName"), value: query.get("context") }
      : null
  );

  const [brand, setBrand] = useState(
    query.get("brand")
      ? { label: query.get("brandName"), value: query.get("brand") }
      : null
  );

  const [warehouse, setWarehouse] = useState(
    query.get("warehouse")
      ? { label: query.get("warehouseName"), value: query.get("warehouse") }
      : null
  );

  const [provider, setProvider] = useState(
    query.get("provider")
      ? { label: query.get("providerName"), value: query.get("provider") }
      : null
  );

  const [sort, setSort] = useState({
    by: query.get("sortBy"),
    dir: query.get("sortDir"),
  });

  const [currentPage, setCurrentPage] = useState(
    query.get("page") ? Number(query.get("page")) : 1
  );
  const getPageSize = localStorage.getItem("pageSize") ? JSON.parse(localStorage.getItem("pageSize")) : {};
  const [pageSize, setPageSize] = useState(getPageSize[pathname] || 10);
  const [lastUpdate, setLastUpdate] = useState(null);

  const getAndSetAnaliticsStock = () => {
    getAnaliticsStock({
      paginationCount: pageSize,
      paginationOffset: (currentPage - 1) * pageSize,
      search,
      priceStart,
      priceEnd,
      brand: brand?.value,
      sort,
      warehouse: warehouse?.value,
      context: context?.value,
    })
      .then((res) => {
        setAnaliticsStock(res.items);
        setAnaliticsStockCount(res.totalItems);
        setLastUpdate(res.dateLastUpdate);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  const getAndSetWarehouses = () => {
    getWarehouses()
      .then(setWarehouses)
      .catch((e) => console.error(e));
  };

  useEffect(() => {
    setLoading(true);
    getAndSetAnaliticsStock();
    getAndSetProgress();
    getAndSetWarehouses();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize, sort]);

  useEffect(() => {
    const url = new URLSearchParams();
    if (currentPage !== 1) {
      url.append("page", currentPage);
    }

    if (search) {
      url.append("search", search);
    }

    if (priceStart) {
      url.append("priceStart", priceStart);
    }

    if (priceEnd) {
      url.append("priceEnd", priceEnd);
    }

    if (sort?.by && sort?.dir) {
      url.append("sortBy", sort.by);
      url.append("sortDir", sort.dir);
    }

    if (brand?.value) {
      url.append("brand", brand?.value);
      url.append("brandName", brand?.label);
    }

    if (provider?.value) {
      url.append("provider", provider?.value);
      url.append("providerName", provider?.label);
    }

    if (warehouse?.value) {
      url.append("warehouse", warehouse?.value);
      url.append("warehouseName", warehouse?.label);
    }

    if (context?.value) {
      url.append("context", context?.value);
      url.append("contextName", context?.label);
    }

    if (url) {
      history.push(`${pathname}?${url}`);
    } else {
      history.push(pathname);
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPage,
    pageSize,
    search,
    priceStart,
    priceEnd,
    brand,
    provider,
    sort,
    warehouse,
    context,
  ]);

  const isSearch = () => {
    if (
      search ||
      priceStart ||
      priceEnd ||
      brand ||
      provider ||
      warehouse ||
      context
    ) {
      return true;
    }
    return false;
  };

  const searchHandler = () => {
    if (!isSearch()) return;
    if (currentPage !== 1) {
      setCurrentPage(1);
      return;
    }
    setLoading(true);
    getAndSetAnaliticsStock();
  };

  const handleKeypress = (e) => {
    if (e.charCode === 13) {
      e.preventDefault();
      searchHandler();
    }
  };

  useEffect(() => {
    setIsShowReset(isSearch());
    if (!isSearch()) {
      setLoading(true);
      getAndSetAnaliticsStock();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, priceStart, priceEnd, brand, sort, provider, warehouse, context]);

  const resetFilter = () => {
    setSearch("");
    setPriceStart("");
    setPriceEnd("");
    setProvider(null);
    setBrand(null);
    setWarehouse(null);
    setContext(null);
  };

  const getAndSetProgress = () => {
    getProgress().then((res) => {
      setProgress(res);

      if (typeof res?.progress !== "undefined") {
        setLoadingGenerate(true);
      } else {
        if (loadingGenerate) {
          toast.success("Генерация отчета завершена");
          setLoadingGenerate(false);
        }
      }
    });
  };

  const generateAnaliticsStockHandler = () => {
    setLoadingGenerate(true);
    toast.success("Генерация отчета запущена");
    generateAnaliticsStock()
      .then((res) => {})
      .catch((e) => {
        toast.error(e.response?.data?.message);
      });
  };

  useEffect(() => {
    if (loadingGenerate) {
      setIntervalBalance(
        setInterval(() => {
          getAndSetProgress();
        }, 3000)
      );
    } else {
      clearInterval(intervalBalance);
      setIntervalBalance(null);
      setLoadingGenerate(false);
    }

    return () => {
      clearInterval(intervalBalance);
    };
  }, [loadingGenerate]);

  const downloadHandler = () => {
    setLoading(true);
    downloadAnaliticsStock({
      search,
      priceStart,
      priceEnd,
      brand: brand?.value,
      sort: sort?.by ? sort : [],
      warehouse: warehouse?.value,
      context: context?.value,
    })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Состояние склада.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onSort = (field, order) => {
    setSort({
      by: field,
      dir: order,
    });
  };

  return (
    <Container fluid className="p-0 analitics-stock">
      {loading && <MySpinner overlay />}
      <Card className="mb-0 filter">
        <CardBody>
          <h1 className="h3 mb-3">Аналитика остатков склада</h1>

          <Form className="mb-3">
            <Row>
              <Col md={3}>
                <Label>Артикул</Label>
                <Input
                  name="search"
                  placeholder="По артикулу"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  onKeyPress={handleKeypress}
                />
              </Col>
              <Col md={3}>
                <Label>Фикс прайс</Label>
                <InputGroup>
                  <Input
                    name="priceStart"
                    placeholder="Цена от"
                    value={priceStart}
                    onChange={(e) => setPriceStart(e.target.value)}
                    onKeyPress={handleKeypress}
                  />

                  <Input
                    name="priceEnd"
                    placeholder="Цена до"
                    value={priceEnd}
                    onChange={(e) => setPriceEnd(e.target.value)}
                    onKeyPress={handleKeypress}
                  />
                </InputGroup>
              </Col>

              <Col md={3}>
                <Label>Бренд</Label>
                <SelectSearch
                  name="brand"
                  placeholder="Выберите бренд"
                  functionSuggestion={brandsSuggestion}
                  value={brand}
                  setValue={setBrand}
                  isClearable
                />
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Label>Склад</Label>
                <Select
                  name="warehouse"
                  placeholder="Выберите склад"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={warehouses.map((item) => {
                    return {
                      label: item.name,
                      value: item.id,
                    };
                  })}
                  value={warehouse}
                  onChange={setWarehouse}
                />
              </Col>
              <Col md={3}>
                <Label>В контексте</Label>
                <Select
                  isClearable
                  name="context"
                  placeholder="Выберите контекст"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={CONTEXT}
                  value={context}
                  onChange={setContext}
                />
              </Col>
              <Col>
                <InputGroup className="with-label">
                  {isShowReset && (
                    <Button color="danger" onClick={() => resetFilter()}>
                      <FontAwesomeIcon icon={faWindowClose} />
                    </Button>
                  )}
                  <Button
                    color="primary"
                    onClick={searchHandler}
                    className="button-wide"
                  >
                    Поиск
                  </Button>
                </InputGroup>
              </Col>
            </Row>
          </Form>

          <Row>
            <Col md="3" className="d-flex flex-column align-items-start">
              <Button
                color="primary"
                className="button-wide btn btn-primary"
                onClick={generateAnaliticsStockHandler}
                disabled={loadingGenerate}
              >
                Сгенерировать
                {loadingGenerate && (
                  <div className="spinner-wrapper">
                    <Spinner color="danger" size="sm" />
                  </div>
                )}
              </Button>
              {loadingGenerate && <>Процесс генерации: {progress.progress} %</>}
            </Col>
            <Col md="3">
              <Button
                color="primary"
                className="button-wide btn btn-primary"
                onClick={downloadHandler}
              >
                Скачать
              </Button>
            </Col>
          </Row>
          <Row>
            <Col md="3">Дата обновления: {dateTimeFormat(lastUpdate)}</Col>
          </Row>
        </CardBody>
      </Card>

      <div>
        <AnaliticsStockTable
          analiticsStock={analiticsStock}
          setAnaliticsStock={setAnaliticsStock}
          setLoading={setLoading}
          getAndSetAnaliticsStock={getAndSetAnaliticsStock}
          onSort={onSort}
          context={context}
        />

        <Card>
          <CardBody>
            <Pagination
              totalCount={analiticsStockCount}
              currentPage={currentPage}
              pageSize={pageSize}
              setCurrentPage={setCurrentPage}
              onChangePageSize={setPageSize}
            />
          </CardBody>
        </Card>
      </div>
    </Container>
  );
};
