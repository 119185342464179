import React, { useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  Label,
  Row,
} from "reactstrap";
import { useHistory, useLocation } from "react-router-dom";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import classNames from "classnames";

import {
  getOrders,
  getOrdersToExcel,
  getReportChangedOrders,
  getStatuses,
  setStatus,
} from "services/api";
import { MySpinner } from "components/MySpinner";
import { Pagination } from "components/Pagination";
import useQuery from "utils/useQuery";
import { useOrders } from "features/Orders/OrdersContext";
import { SelectCustomer } from "features/Dashboard/SelectCustomer";
import { OrdersTable } from "features/Orders/OrdersTable";
import { dateFormatU, dateTimeFormat, priceFormat } from "utils/format";
import { colorStyles, selectedStatuses } from "utils/select2";
import { useUser } from "features/Users/UserContext";

import "./orders.scss";
import { PAYMENTS } from "pages/orderOne";

registerLocale("ru", ru);

export const SOURCES = [
  {
    value: "site",
    label: "Сайт",
  },
  {
    value: "clientApi",
    label: "QWEP",
  },
  {
    value: "",
    label: "Менеджер",
  },
];

export const OrdersPage = () => {
  const history = useHistory();
  const { setStatuses, status, statuses, loading, setLoading } = useOrders();
  const { pathname } = useLocation();
  const query = useQuery(useLocation);
  const { admins } = useUser();
  const [orders, setOrders] = useState([]);
  const [ordersCount, setOrdersCount] = useState(0);
  const [ordersSumm, setOrdersSumm] = useState(0);
  const [currentPage, setCurrentPage] = useState(
    query.get("page") ? Number(query.get("page")) : 1
  );
  const getPageSize = localStorage.getItem("pageSize") ? JSON.parse(localStorage.getItem("pageSize")) : {};
  const [pageSize, setPageSize] = useState(getPageSize[pathname] || 10);
  const [customer, setCustomer] = useState(
    query.get("customer")
      ? { label: query.get("customerName"), value: query.get("customer") }
      : null
  );
  const [search, setSearch] = useState(query.get("search") || "");
  const [dateStart, setDateStart] = useState(
    query.get("dateStart") ? new Date(query.get("dateStart")) : ""
  );
  const [dateEnd, setDateEnd] = useState(
    query.get("dateEnd") ? new Date(query.get("dateEnd")) : ""
  );
  const [filterStatus, setFilterStatus] = useState(
    localStorage.getItem("filterStatus")
      ? JSON.parse(localStorage.getItem("filterStatus"))
      : []
  );
  const [number, setNumber] = useState(query.get("number") || "");
  const [isShowReset, setIsShowReset] = useState(false);
  const [admin, setAdmin] = useState(
    localStorage.getItem("filterAdmin")
      ? JSON.parse(localStorage.getItem("filterAdmin"))
      : null
  );
  const [number1C, setNumber1C] = useState(query.get("number1C") || "");

  const [payment, setPayment] = useState(
    query.get("payment")
      ? { label: query.get("paymentName"), value: query.get("payment") }
      : null
  );

  const [source, setSource] = useState(
    query.get("source")
      ? { label: query.get("sourceName"), value: query.get("source") }
      : null
  );

  const [shippingDate, setShippingDate] = useState(
    query.get("shippingDate") || false
  );

  const getAndSetStatuses = () => {
    getStatuses()
      .then((res) => {
        setStatuses(res);
      })
      .catch((e) => console.error(e));
  };

  const getAndSetOrders = () => {
    getOrders({
      paginationCount: pageSize,
      paginationOffset: (currentPage - 1) * pageSize,
      search,
      dateStart: dateFormatU(dateStart),
      dateEnd: dateFormatU(dateEnd),
      number,
      number1C,
      customer: customer?.value,
      admin: admin?.value,
      status: filterStatus.map((val) => val.value),
      payment: payment?.value,
      shippingDate: shippingDate ? 1 : 0,
      source: source?.value,
    })
      .then((res) => {
        setOrders(res.items);
        setOrdersCount(res.totalItems);
        setOrdersSumm(res.totalSumm);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setLoading(true);
    getAndSetOrders();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize, status]);

  const statusHandler = (status, order) => {
    setLoading(true);
    setStatus({
      orderId: order?.id,
      statusId: status?.id,
    })
      .then(() => {
        getAndSetOrders();
      })
      .catch((e) => {
        console.error(e);
      });
  };

  useEffect(() => {
    const url = new URLSearchParams();
    if (currentPage !== 1) {
      url.append("page", currentPage);
    }

    if (search) {
      url.append("search", search);
    }

    if (customer?.value) {
      url.append("customer", customer?.value);
      url.append("customerName", customer?.label);
    }

    if (dateStart) {
      url.append("dateStart", dateFormatU(dateStart));
    }

    if (dateEnd) {
      url.append("dateEnd", dateFormatU(dateEnd));
    }

    if (number) {
      url.append("number", number);
    }

    if (number1C) {
      url.append("number1С", number1C);
    }

    if (payment) {
      url.append("payment", payment?.value);
      url.append("paymentName", payment?.label);
    }

    if (source) {
      url.append("source", source?.value);
      url.append("sourceName", source?.label);
    }

    if (shippingDate) {
      url.append("shippingDate", shippingDate);
    }

    if (url) {
      history.push(`${pathname}?${url}`);
    } else {
      history.push(pathname);
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPage,
    pageSize,
    search,
    customer,
    dateStart,
    dateEnd,
    number,
    number1C,
    payment,
    filterStatus,
    shippingDate,
  ]);

  useEffect(() => {
    setLoading(true);
    getAndSetStatuses();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectHandler = (val, res) => {
    switch (res.name) {
      case "admin":
        localStorage.setItem("filterAdmin", JSON.stringify(val));
        setAdmin(val);
        break;
      case "payment":
        setPayment(val);
        break;
      case "source":
        setSource(val);
        break;
      case "status":
        localStorage.setItem("filterStatus", JSON.stringify(val));
        setFilterStatus(val);
        break;
      default:
        break;
    }
  };

  const isSearch = () => {
    if (
      search ||
      dateStart ||
      dateEnd ||
      number ||
      customer?.value ||
      admin?.value ||
      number1C ||
      payment ||
      shippingDate ||
      source
    ) {
      return true;
    }
    return false;
  };

  const searchHandler = () => {
    if (!isSearch()) return;
    if (currentPage !== 1) {
      setCurrentPage(1);
      return;
    }
    setLoading(true);
    getAndSetOrders();
  };

  const handleKeypress = (e) => {
    if (e.charCode === 13) {
      e.preventDefault();
      searchHandler();
    }
  };

  useEffect(() => {
    setIsShowReset(isSearch());
    if (!isSearch()) {
      setLoading(true);
      getAndSetOrders();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    search,
    dateStart,
    dateEnd,
    number,
    customer,
    admin,
    number1C,
    filterStatus,
    payment,
    shippingDate,
    source,
  ]);

  const resetFilter = () => {
    setCustomer(null);
    setSearch("");
    setDateStart("");
    setDateEnd("");
    setShippingDate("");
    setNumber("");
    setPayment(null);
    setAdmin(null);
    setNumber1C("");
    setSource(null);
    localStorage.removeItem("filterAdmin");
  };

  const statusesList = () => {
    return statuses.map((val) => {
      return {
        label: val.name,
        value: val.id,
        color: val.color,
      };
    });
  };

  const numberHandler = (e) => {
    setNumber(e.target.value.replace(/[^+\d]/g, ""));
  };

  const downloadOrdersToExcel = () => {
    setLoading(true);
    getOrdersToExcel({
      search,
      dateStart: dateFormatU(dateStart),
      dateEnd: dateFormatU(dateEnd),
      number,
      number1C,
      customer: customer?.value,
      admin: admin?.value,
      status: filterStatus.map((val) => val.value),
      payment: payment?.value,
      shippingDate: shippingDate ? 1 : 0,
      source: source?.value,
    })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Заказы_${dateTimeFormat(new Date())}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getReportChangedOrdersHandler = () => {
    setLoading(true);
    getReportChangedOrders({
      dateStart: dateFormatU(dateStart),
      dateEnd: dateFormatU(dateEnd),
    })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Список измененных заказов за предыдущие периоды.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Container fluid className="p-0 orders">
      {loading && <MySpinner overlay />}
      <Card className="mb-0 filter">
        <CardBody>
          <h1 className="h3 mb-3">Заказы</h1>

          <Form className="mb-3">
            <Row>
              <Col>
                <Label>Дата</Label>
                <div className="filter__type-date">
                  <button
                    className={classNames("filter__type-date-item", {
                      active: !shippingDate,
                    })}
                    type="button"
                    onClick={() => setShippingDate(false)}
                  >
                    Заказа
                  </button>
                  <button
                    className={classNames("filter__type-date-item", {
                      active: shippingDate,
                    })}
                    type="button"
                    onClick={() => setShippingDate(true)}
                  >
                    Реализации
                  </button>
                </div>
                <InputGroup className="daterange">
                  <DatePicker
                    placeholderText="от"
                    selected={dateStart}
                    onChange={setDateStart}
                    selectsStart
                    startDate={dateStart}
                    endDate={dateEnd}
                    dateFormat="dd.MM.yyyy"
                    locale="ru"
                    className="form-control date-start"
                  />
                  <DatePicker
                    placeholderText="до"
                    selected={dateEnd}
                    onChange={setDateEnd}
                    selectsEnd
                    startDate={dateStart}
                    endDate={dateEnd}
                    minDate={dateStart}
                    dateFormat="dd.MM.yyyy"
                    locale="ru"
                    className="form-control date-end"
                  />
                </InputGroup>
              </Col>
              <Col>
                <Label>Заказ №</Label>
                <Input
                  name="number"
                  placeholder="По номеру"
                  value={number}
                  onChange={numberHandler}
                  onKeyUp={handleKeypress}
                />
              </Col>
              <Col>
                <Label>Артикул</Label>
                <Input
                  name="search"
                  placeholder="По артикулу"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  onKeyUp={handleKeypress}
                />
              </Col>
              <Col>
                <Label>Клиент</Label>
                <SelectCustomer customer={customer} setCustomer={setCustomer} />
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <Label>Тип оплаты</Label>
                <Select
                  name="payment"
                  placeholder="Тип оплаты"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={PAYMENTS}
                  value={payment}
                  onChange={selectHandler}
                  isClearable
                />
              </Col>
              <Col md={2}>
                <Label>Менеджер</Label>
                <Select
                  name="admin"
                  placeholder="Менеджер"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={admins}
                  value={admin}
                  onChange={selectHandler}
                  isClearable
                />
              </Col>
              <Col md={2}>
                <Label>Источник</Label>
                <Select
                  name="source"
                  placeholder="Источник"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={SOURCES}
                  value={source}
                  onChange={selectHandler}
                  isClearable
                />
              </Col>
              <Col md={4}>
                <Label>Статус</Label>
                <Select
                  isMulti
                  name="status"
                  placeholder="Статус"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={statusesList()}
                  value={selectedStatuses(statuses, filterStatus)}
                  onChange={selectHandler}
                  styles={colorStyles}
                />
              </Col>
              <Col md={2}>
                <InputGroup className="with-label">
                  {isShowReset && (
                    <Button color="danger" onClick={() => resetFilter()}>
                      <FontAwesomeIcon icon={faWindowClose} />
                    </Button>
                  )}
                  <Button
                    color="primary"
                    onClick={searchHandler}
                    className="button-wide"
                  >
                    Поиск
                  </Button>
                </InputGroup>
              </Col>
            </Row>
            <Row>
              <Col md={2}>Сумма: {priceFormat(ordersSumm)}</Col>
              <Col md={3} className="d-flex">
                <Button onClick={downloadOrdersToExcel}>Реестр Excel</Button>
                <Button
                  onClick={getReportChangedOrdersHandler}
                  className="ml-2"
                >
                  Изм.заказы Excel
                </Button>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>

      <div>
        <OrdersTable orders={orders} statusHandler={statusHandler} />

        <Card>
          <CardBody>
            <Pagination
              totalCount={ordersCount}
              currentPage={currentPage}
              pageSize={pageSize}
              setCurrentPage={setCurrentPage}
              onChangePageSize={setPageSize}
            />
          </CardBody>
        </Card>
      </div>
    </Container>
  );
};
