import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { PlusSquare, X as XIcon } from "react-feather";
import classNames from "classnames";
import Barcode from "react-barcode";
import ReactInputMask from "react-input-mask";

import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import {
  addCell,
  brandsSuggestion,
  cellSuggestion,
  createNomenclature,
  generateBarcode,
  getCrossByArticleBrand,
  getNomenclatureOne,
  nomenclatureAddImages,
  nomenclatureRemoveImages,
  updateNomenclature,
} from "services/api";
import { CloseButton } from "components/CloseButton";
import { MySpinner } from "components/MySpinner";
import { SelectSearch } from "components/SelectSearch";

import "./nomenclatureModal.scss";
import { Characteristics } from "../Characteristics";
import noImage from "assets/img/noimage.jpg";
import { Oem } from "../Oem";
import { Checkbox } from "components/Checkbox";
import { ChangeLogNomenclature } from "../ChangeLogNomenclature";
import { RelatedProducts } from "../ReleatedProducts";

export const NomenclatureModal = ({
  openModal,
  setOpenModal,
  id,
  getAndSetNomenclature,
}) => {
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [article, setArticle] = useState("");
  const [brand, setBrand] = useState(null);
  const [name, setName] = useState("");
  const [barcode, setBarcode] = useState("");
  const [id1C, setId1C] = useState("");
  const [weight, setWeight] = useState("");
  const [length, setLength] = useState("");
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const [minPrice, setMinPrice] = useState("");
  const [costPrice, setCostPrice] = useState("");
  const [fixPrice, setFixPrice] = useState("");
  const [marketplacePrice, setMarketplacePrice] = useState("");
  const [disableAutoUpdate, setDisableAutoUpdate] = useState(false);
  const [errors, setErrors] = useState([]);
  const [images, setImages] = useState([]);
  const [needValid, setNeedValid] = useState(true);
  const [selectedImage, setSelectedImage] = useState("");
  const inputImage = useRef();
  const [addedImages, setAddedImages] = useState([]);
  const [cell, setCell] = useState(null);
  const [characteristicsValue, setCharacteristicsValue] = useState([]);
  const [cross, setCross] = useState([]);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [site, setSite] = useState(false);
  const [productGroups, setProductGroups] = useState([]);
  const [showNewCell, setShowNewCell] = useState(false);
  const [newCell, setNewCell] = useState("");
  const [analogueCode, setAnalogueCode] = useState("");

  const getAndSetCross = () => {
    getCrossByArticleBrand({
      article,
      brand: brand?.label,
    })
      .then((res) => {
        setCross(res);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  const getAndSetNomenclatureOne = () => {
    if (!id) return;

    getNomenclatureOne(id)
      .then((res) => {
        setActive(res.active);
        setArticle(res.article);
        setName(res.name);
        setBrand({
          label: res.brand?.name,
          value: res.brand?.id,
        });
        setId1C(res.id1C);
        setWeight(res.weight);
        setLength(res.size?.length);
        setWidth(res.size?.width);
        setHeight(res.size?.height);
        setMinPrice(res.minPrice);
        setCostPrice(res.costPrice);
        setFixPrice(res.fixPrice);
        setMarketplacePrice(res.marketplacePrice);
        setCell({ label: res.cell?.name, value: res.cell?.id });
        setDisableAutoUpdate(res.disableAutoUpdate);
        setImages(res.images || []);
        setCharacteristicsValue(res.characteristicsValue);
        setCross(res.cross || []);
        setSite(res.site);
        setBarcode(res.barcode);
        setProductGroups(res.productGroups);
        setRelatedProducts(res.relatedProducts);
        setAnalogueCode(res.analogueCode || "");
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (!id) return;
    setLoading(true);
    setActive("");
    setArticle("");
    setName("");
    setBrand(null);
    setId1C("");
    setWeight("");
    setLength("");
    setWidth("");
    setHeight("");
    setMinPrice("");
    setCostPrice("");
    setFixPrice("");
    setMarketplacePrice("");
    setCell(null);
    setDisableAutoUpdate("");
    setImages([]);
    setSelectedImage("");
    setBarcode("");
    setCross([]);
    setSite(false);
    getAndSetNomenclatureOne();
  }, [id]);

  const editHandler = () => {
    setLoading(true);
    updateNomenclature({
      id,
      name,
      article,
      brand: brand?.value,
      id1C,
      active,
      weight,
      width,
      length,
      height,
      minPrice,
      fixPrice,
      marketplacePrice,
      cell: cell?.value || "",
      disableAutoUpdate,
      site,
      barcode,
    })
      .then((res) => {
        getAndSetNomenclatureOne();
        toast.success("Номенклатура успешно сохранена");

        if (getAndSetNomenclature) {
          getAndSetNomenclature();
        }
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
      });
  };

  const createHandler = () => {
    setLoading(true);
    createNomenclature({
      name,
      article,
      brand: brand.value,
      id1C,
      active,
      weight,
      width,
      length,
      height,
      minPrice,
      costPrice,
      fixPrice,
      marketplacePrice,
      cell: cell?.value || "",
      disableAutoUpdate,
      site,
      barcode,
    })
      .then((res) => {
        getAndSetNomenclatureOne();
        toast.success("Номенклатура успешно создана");

        if (getAndSetNomenclature) {
          getAndSetNomenclature();
        }
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
      })
      .finally(() => setLoading(false));
  };

  const renderImage = () => {
    if (images.length > 0 && !selectedImage) {
      return (
        <img
          src={`${process.env.REACT_APP_BASE_URL}/image.view?file=${images[0].key}`}
          alt={name}
        />
      );
    }

    if (selectedImage) {
      return (
        <img
          src={`${process.env.REACT_APP_BASE_URL}/image.view?file=${selectedImage}`}
          alt={name}
        />
      );
    }

    return <img src={noImage} alt={name} />;
  };

  const getImages = () => {
    if (inputImage) {
      inputImage.current.click();
    }
  };

  useEffect(() => {
    if (!addedImages.length) return;
    const formData = new FormData();
    for (const image of addedImages) {
      formData.append("images[]", image);
    }
    formData.append("id", id);
    setAddedImages([]);
    setLoading(true);
    nomenclatureAddImages(formData)
      .then((res) => {
        getAndSetNomenclatureOne();
        toast.success("Изображения успешно добавлены");
        if (getAndSetNomenclature) {
          getAndSetNomenclature();
        }
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  }, [addedImages]);

  useEffect(() => {
    console.log(productGroups);
  }, [productGroups]);

  const removeImage = (id) => {
    setLoading(true);
    nomenclatureRemoveImages({ id })
      .then((res) => {
        getAndSetNomenclatureOne();
        toast.success("Изображения успешно удалены");
        if (getAndSetNomenclature) {
          getAndSetNomenclature();
        }
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const renderGroup = (group) => (
    <div className="nomenclature-modal__group">
      {group.map((item, i) => (
        <span key={item.id} className="nomenclature-modal__category">
          {item.name}
        </span>
      ))}
    </div>
  );

  const addCellHandler = () => {
    const regex = /^\d{1,2}-[A-Za-z]-\d{1,2}$/;
    if (!regex.test(newCell)) {
      toast.error("Ячейка должна быть вида 9-A-9 или 99-A-99");
      return;
    }
    setLoading(true);
    addCell({ name: newCell })
      .then((res) => {
        toast.success("Ячейка успешно добавлена");
      })
      .catch((e) => toast.error(e.response?.data?.message))
      .finally(() => {
        setLoading(false);
        setShowNewCell(false);
      });
  };

  const generateBarcodeHandler = () => {
    setLoading(true);
    generateBarcode({ id })
      .then((res) => {
        toast.success("Штрихкод успешно сгенерирован");
        setBarcode(res.barcode);
      })
      .catch((e) => toast.error(e.response?.data?.message))
      .finally(() => {
        setLoading(false);
        setShowNewCell(false);
      });
  };

  return (
    <Modal
      isOpen={openModal}
      toggle={() => setOpenModal(false)}
      className="nomenclature-modal"
    >
      {loading && <MySpinner overlay />}
      <ModalHeader>
        <div className="modal-title-text">
          {article} {brand?.label}
          <span className="nomenclature-modal__id">ID: {id}</span>
          <ChangeLogNomenclature
            id={id}
            className="nomenclature-modal__changelog"
          />
        </div>
        <CloseButton onClick={() => setOpenModal(false)} />
      </ModalHeader>
      <ModalBody className="m-3">
        <Row className="main-info">
          <Col md="5">
            <div className="nomenclature-modal__image">{renderImage()}</div>
            <div className="nomenclature-modal__miniatures">
              {images.map((image) => (
                <div
                  className={classNames("nomenclature-modal__miniature", {
                    selected: image.key === selectedImage,
                  })}
                  key={image.id}
                  onClick={() => setSelectedImage(image.key)}
                >
                  <button
                    className="nomenclature-modal__remove"
                    onClick={() => removeImage(image.id)}
                  >
                    <XIcon color="white" size={13} />
                  </button>
                  <img
                    src={`${process.env.REACT_APP_BASE_URL}/image.view?file=${image.key}`}
                  />
                </div>
              ))}

              <button className="nomenclature-modal__add" onClick={getImages}>
                <PlusSquare size={20} color="gray" />
              </button>

              <Input
                type="file"
                id="images"
                multiple
                hidden
                innerRef={inputImage}
                onChange={(e) => setAddedImages(e.target.files)}
                accept=".jpeg,.jpg,.png"
              />
            </div>
            <div className="nomenclature-modal__barcode">
              {barcode && (
                <div className="nomenclature-modal__barcode-img">
                  <div className="print">
                    <Barcode value={barcode} format="EAN13" />
                  </div>
                  <Button href={`/reference/nomenclature/${id}/print`}>
                    Печать
                  </Button>
                </div>
              )}
            </div>
          </Col>
          <Col md="7" className="tab">
            <Row className="mb-2">
              <Col>
                <Label>Выгружать на сайт</Label>
                <Checkbox
                  value="site"
                  name="site"
                  checked={site}
                  onChange={(e) => setSite(e.target.checked)}
                />
              </Col>
            </Row>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classNames({
                    active: activeTab === "1",
                  })}
                  onClick={() => toggleTab("1")}
                >
                  Основные данные
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classNames({
                    active: activeTab === "2",
                  })}
                  onClick={() => toggleTab("2")}
                >
                  Характеристики
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classNames({
                    active: activeTab === "3",
                  })}
                  onClick={() => toggleTab("3")}
                >
                  ОЕМ номера
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classNames({
                    active: activeTab === "4",
                  })}
                  onClick={() => toggleTab("4")}
                >
                  Сопутствующие товары
                </NavLink>
              </NavItem>
            </Nav>

            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                Код аналога: {analogueCode}
                <Row>
                  <Col md="6">
                    <Label>Артикул</Label>
                    <Input
                      name="article"
                      placeholder="Артикул"
                      value={article}
                      onChange={(e) => setArticle(e.target.value)}
                      invalid={needValid && errors.hasOwnProperty("article")}
                    />
                  </Col>
                  <Col md="6">
                    <Label>Бренд</Label>
                    <SelectSearch
                      name="brand"
                      placeholder="Выберите бренд"
                      functionSuggestion={brandsSuggestion}
                      value={brand}
                      setValue={setBrand}
                      needValid={needValid}
                      errors={errors}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Label>Наименование</Label>
                    <Input
                      name="name"
                      placeholder="Наименование"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      invalid={needValid && errors.hasOwnProperty("name")}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Label>Вес, кг</Label>
                    <Input
                      name="weight"
                      placeholder="Вес, кг"
                      value={weight || ""}
                      onChange={(e) => setWeight(e.target.value)}
                    />
                  </Col>
                  <Col>
                    <Label>Длина, мм</Label>
                    <Input
                      name="length"
                      placeholder="Длина, мм"
                      value={length || ""}
                      onChange={(e) => setLength(e.target.value)}
                    />
                  </Col>
                  <Col>
                    <Label>Ширина, мм</Label>
                    <Input
                      name="width"
                      placeholder="Ширина, мм"
                      value={width || ""}
                      onChange={(e) => setWidth(e.target.value)}
                    />
                  </Col>
                  <Col>
                    <Label>Высота, мм</Label>
                    <Input
                      name="height"
                      placeholder="Высота, мм"
                      value={height || ""}
                      onChange={(e) => setHeight(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className="prices">
                  <Col md="3">
                    <Label>Себестоимость</Label>
                    <Input
                      name="costPrice"
                      placeholder="Себестоимость"
                      value={costPrice || ""}
                      onChange={(e) => setCostPrice(e.target.value)}
                      disabled
                    />
                  </Col>
                  <Col md="3">
                    <Label>Площадки</Label>
                    <Input
                      name="marketplacePrice"
                      placeholder="Площадки"
                      value={marketplacePrice || ""}
                      onChange={(e) => setMarketplacePrice(e.target.value)}
                    />
                  </Col>
                  <Col md="3">
                    <Label>Фикс цена</Label>
                    <Input
                      name="fixPrice"
                      placeholder="Фиксированая цена"
                      value={fixPrice || ""}
                      onChange={(e) => setFixPrice(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className="warehouse">
                  <Col md="5">
                    <Label>Ячейка</Label>
                    <div className="cell d-flex">
                      <SelectSearch
                        isClearable
                        name="cell"
                        placeholder="Выберите ячейку"
                        functionSuggestion={cellSuggestion}
                        value={cell}
                        setValue={setCell}
                      />
                      <Button
                        className="cell__showmodal"
                        onClick={() => setShowNewCell(true)}
                      >
                        +
                      </Button>
                      {showNewCell && (
                        <div className="add-cell">
                          <div>Ячейка должна быть вида 9-A-9 или 99-A-99</div>
                          <div>
                            <Input
                              className="form-control"
                              name="newCell"
                              placeholder="Добавить ячейку"
                              value={newCell || ""}
                              onChange={(e) => setNewCell(e.target.value)}
                            />
                          </div>
                          <div className="d-flex">
                            <Button onClick={addCellHandler}>Добавить</Button>
                            <Button onClick={() => setShowNewCell(false)}>
                              Отменить
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col md="7">
                    <Label>Штрихкод</Label>
                    <div className="barcode d-flex">
                      <Input
                        name="barcode"
                        placeholder="Штрихкод"
                        value={barcode || ""}
                        onChange={(e) => setBarcode(e.target.value)}
                      />
                      <Button
                        className="barcode__generate"
                        onClick={generateBarcodeHandler}
                      >
                        +
                      </Button>
                    </div>
                  </Col>
                </Row>
                <h5 className="mt-3">Товарные группы</h5>
                <Row class>
                  <Col>{productGroups.map((group) => renderGroup(group))}</Col>
                </Row>
                <div className="d-flex justify-content-end mt-3">
                  <Button
                    onClick={id ? editHandler : createHandler}
                    color="primary"
                  >
                    Сохранить
                  </Button>
                </div>
              </TabPane>
              <TabPane tabId="2">
                <Characteristics
                  id={id}
                  setCharacteristicsValue={setCharacteristicsValue}
                  characteristicsValue={characteristicsValue}
                />
              </TabPane>
              <TabPane tabId="3">
                <Oem
                  cross={cross}
                  getAndSetCross={getAndSetCross}
                  setLoading={setLoading}
                  article={article}
                  brand={brand}
                />
              </TabPane>
              <TabPane tabId="4">
                <RelatedProducts
                  id={id}
                  setLoading={setLoading}
                  relatedProducts={relatedProducts}
                  getAndSetNomenclatureOne={getAndSetNomenclatureOne}
                />
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};
