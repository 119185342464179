import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { dashboard as dashboardRoutes, page as pageRoutes } from "./index";

import DashboardLayout from "../layouts/Dashboard";
import AuthLayout from "../layouts/Auth";
import Page404 from "../pages/auth/Page404";

import ScrollToTop from "../components/ScrollToTop";
import { useCart } from "../features/Cart/CartContext";
import { useUser } from "../features/Users/UserContext";
import { cartGet, getAdminsForSelect, getCurrentUser } from "../services/api";

const childRoutes = (Layout, routes) =>
  routes.map(({ children, path, component: Component, title = "" }, index) =>
    children ? (
      // Route item with children
      children.map(({ path, component: Component }, index) => (
        <Route
          key={index}
          path={path}
          exact
          render={(props) => (
            <Layout title={title}>
              <Component {...props} />
            </Layout>
          )}
        />
      ))
    ) : (
      // Route item without children
      <Route
        key={index}
        path={path}
        exact
        render={(props) => (
          <Layout title={title}>
            <Component {...props} />
          </Layout>
        )}
      />
    )
  );

const Routes = () => {
  const { setCart, setCartLoading } = useCart();
  const { setUser, setUserLoading, setAdmins } = useUser();

  useEffect(() => {
    const getAndSetUser = () => {
      setUserLoading(true);
      getCurrentUser()
        .then(setUser)
        .catch((e) => console.error(e))
        .finally(() => {
          setUserLoading(false);
        });
    };
    const getAndSetAdmins = () => {
      getAdminsForSelect()
        .then((res) => {
          const getAdmins = res.map((item) => ({
            value: item.id,
            label: item.name,
            department: item.department,
          }));
          setAdmins(getAdmins);
        })
        .catch((e) => console.error(e));
    };
    const getAndSetCart = () => {
      setCartLoading(true);
      cartGet()
        .then((res) => setCart(res.cart))
        .catch((e) => console.error(e))
        .finally(() => {
          setCartLoading(false);
        });
    };
    if (
      localStorage.getItem("accessToken") &&
      localStorage.getItem("refreshToken")
    ) {
      getAndSetUser();
      getAndSetCart();
      getAndSetAdmins();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Router>
      <ScrollToTop>
        <Switch>
          {childRoutes(DashboardLayout, dashboardRoutes)}
          {childRoutes(AuthLayout, pageRoutes)}
          <Route
            render={() => (
              <AuthLayout>
                <Page404 />
              </AuthLayout>
            )}
          />
        </Switch>
      </ScrollToTop>
    </Router>
  );
};

export default Routes;
