import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";

import useQuery from "utils/useQuery";
import { Pagination } from "components/Pagination";
import {
  downloadAnaliticsPrice,
  generateAnaliticsPrice,
  getAnaliticsPrice,
  getProgressAnaliticsPrice,
  importAnaliticsPrice,
} from "services/apiAnalitics";
import { SelectProvider } from "components/SelectProvider";
import { AnaliticsPriceTable } from "features/AnaliticsPrice/ReturnsCustomerTable";
import { SelectSearch } from "components/SelectSearch";
import { brandsSuggestion } from "services/api";
import { MySpinner } from "components/MySpinner";
import { dateTimeFormat } from "utils/format";

import "./analiticsPrice.scss";

export const AnaliticsPricePage = () => {
  const history = useHistory();
  const inputFile = useRef(null);
  const { pathname } = useLocation();
  const query = useQuery(useLocation);
  const [analiticsPrice, setAnaliticsPrice] = useState([]);
  const [analiticsPriceCount, setAnaliticsPriceCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState(query.get("search") || "");
  const [isShowReset, setIsShowReset] = useState(false);
  const [provider, setProvider] = useState(
    query.get("provider")
      ? { label: query.get("providerName"), value: query.get("provider") }
      : null
  );
  const [priceStart, setPriceStart] = useState("");
  const [priceEnd, setPriceEnd] = useState("");
  const [percentStart, setPercentStart] = useState("");
  const [percentEnd, setPercentEnd] = useState("");
  const [brand, setBrand] = useState(
    query.get("brand")
      ? { label: query.get("brandName"), value: query.get("brand") }
      : null
  );
  const [intervalBalance, setIntervalBalance] = useState(null);
  const [currentPage, setCurrentPage] = useState(
    query.get("page") ? Number(query.get("page")) : 1
  );
  const getPageSize = localStorage.getItem("pageSize") ? JSON.parse(localStorage.getItem("pageSize")) : {};
  const [pageSize, setPageSize] = useState(getPageSize[pathname] || 10);
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState({});
  const [loadingGenerate, setLoadingGenerate] = useState(false);
  const [lastUpdate, setLastUpdate] = useState(null);

  const getAndSetAnaliticsPrice = () => {
    getAnaliticsPrice({
      paginationCount: pageSize,
      paginationOffset: (currentPage - 1) * pageSize,
      search,
      provider: provider?.value,
      priceStart,
      priceEnd,
      percentStart,
      percentEnd,
      brand: brand?.label,
    })
      .then((res) => {
        setAnaliticsPrice(res.items);
        setAnaliticsPriceCount(res.totalItems);
        setLastUpdate(res.dateLastUpdate);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  // useEffect(() => {
  //   setLoading(true);
  //   getAndSetAnaliticsPrice();
  //   //eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    setLoading(true);
    getAndSetProgress();
    getAndSetAnaliticsPrice();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize]);

  useEffect(() => {
    const url = new URLSearchParams();
    if (currentPage !== 1) {
      url.append("page", currentPage);
    }

    if (search) {
      url.append("search", search);
    }

    if (provider?.value) {
      url.append("provider", provider?.value);
      url.append("providerName", provider?.label);
    }

    if (priceStart) {
      url.append("priceStart", priceStart);
    }

    if (priceEnd) {
      url.append("priceEnd", priceEnd);
    }

    if (percentStart) {
      url.append("percentStart", percentStart);
    }

    if (percentEnd) {
      url.append("percentEnd", percentEnd);
    }

    if (brand?.value) {
      url.append("brand", brand?.value);
      url.append("brandName", brand?.label);
    }

    if (url) {
      history.push(`${pathname}?${url}`);
    } else {
      history.push(pathname);
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPage,
    pageSize,
    search,
    provider,
    priceStart,
    priceEnd,
    percentStart,
    percentEnd,
    brand,
  ]);

  const isSearch = () => {
    if (
      search ||
      provider?.value ||
      priceStart ||
      priceEnd ||
      percentStart ||
      percentEnd ||
      brand
    ) {
      return true;
    }
    return false;
  };

  const searchHandler = () => {
    if (!isSearch()) return;
    if (currentPage !== 1) {
      setCurrentPage(1);
      return;
    }
    setLoading(true);
    getAndSetAnaliticsPrice();
  };

  const handleKeypress = (e) => {
    if (e.charCode === 13) {
      e.preventDefault();
      searchHandler();
    }
  };

  useEffect(() => {
    setIsShowReset(isSearch());
    if (!isSearch()) {
      setLoading(true);
      getAndSetAnaliticsPrice();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, provider, priceStart, priceEnd, percentStart, percentEnd, brand]);

  const resetFilter = () => {
    setSearch("");
    setPriceStart("");
    setPriceEnd("");
    setPercentStart("");
    setPercentEnd("");
    setProvider(null);
    setBrand(null);
  };

  useEffect(() => {
    if (!file) return;
    setLoading(true);
    const formData = new FormData();
    formData.append("file", file);

    importAnaliticsPrice(formData)
      .then((res) => {
        getAndSetAnaliticsPrice();
      })
      .catch((e) => toast.error(e.response?.data?.message))
      .finally(() => {
        toast.success("Загрузка успешно выполнена");
        inputFile.current.value = "";
      });

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  const getAnaliticsPriceFile = () => {
    if (inputFile) {
      inputFile.current.click();
    }
  };

  const downloadHandler = () => {
    setLoading(true);
    downloadAnaliticsPrice({
      search,
      brand: brand?.value,
      provider: provider?.value,
    })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Аналитика минимальных цен поставщиков.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (loadingGenerate) {
      setIntervalBalance(
        setInterval(() => {
          getAndSetProgress();
        }, 3000)
      );
    } else {
      clearInterval(intervalBalance);
      setIntervalBalance(null);
      setLoadingGenerate(false);
    }

    return () => {
      clearInterval(intervalBalance);
    };
  }, [loadingGenerate]);

  const getAndSetProgress = () => {
    getProgressAnaliticsPrice().then((res) => {
      setProgress(res);

      if (typeof res?.progress !== "undefined") {
        setLoadingGenerate(true);
      } else {
        if (loadingGenerate) {
          toast.success("Генерация отчета завершена");
          setLoadingGenerate(false);
        }
      }
    });
  };

  const generateAnaliticsPriceHandler = () => {
    setLoadingGenerate(true);
    toast.success("Генерация отчета запущена");
    generateAnaliticsPrice()
      .then((res) => {})
      .catch((e) => {
        toast.error(e.response?.data?.message);
      });
  };

  return (
    <Container fluid className="p-0 analitics-price">
      {loading && <MySpinner overlay />}
      <Card className="mb-0 filter">
        <CardBody>
          <h1 className="h3 mb-3">Аналитика минимальных цен поставщиков</h1>

          <Form className="mb-3">
            <Row>
              <Col md={3}>
                <Label>Артикул</Label>
                <Input
                  name="search"
                  placeholder="По артикулу"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  onKeyPress={handleKeypress}
                />
              </Col>

              <Col md={3}>
                <Label>Поставщик</Label>
                <SelectProvider provider={provider} setProvider={setProvider} />
              </Col>

              <Col md={3}>
                <Label>Цена</Label>
                <InputGroup>
                  <Input
                    name="priceStart"
                    placeholder="Цена от"
                    value={priceStart}
                    onChange={(e) => setPriceStart(e.target.value)}
                    onKeyPress={handleKeypress}
                  />

                  <Input
                    name="priceEnd"
                    placeholder="Цена до"
                    value={priceEnd}
                    onChange={(e) => setPriceEnd(e.target.value)}
                    onKeyPress={handleKeypress}
                  />
                </InputGroup>
              </Col>
              <Col md={3}>
                <Label>Разница</Label>
                <InputGroup>
                  <Input
                    name="percentStart"
                    placeholder="Процент от"
                    value={percentStart}
                    onChange={(e) => setPercentStart(e.target.value)}
                    onKeyPress={handleKeypress}
                  />

                  <Input
                    name="percentEnd"
                    placeholder="Процент до"
                    value={percentEnd}
                    onChange={(e) => setPercentEnd(e.target.value)}
                    onKeyPress={handleKeypress}
                  />
                </InputGroup>
              </Col>
            </Row>

            <Row>
              <Col md={3}>
                <Label>Бренд</Label>
                <SelectSearch
                  name="brand"
                  placeholder="Выберите бренд"
                  functionSuggestion={brandsSuggestion}
                  value={brand}
                  setValue={setBrand}
                />
              </Col>
              <Col md="2">
                <InputGroup className="with-label">
                  {isShowReset && (
                    <Button color="danger" onClick={() => resetFilter()}>
                      <FontAwesomeIcon icon={faWindowClose} />
                    </Button>
                  )}
                  <Button
                    color="primary"
                    onClick={searchHandler}
                    className="button-wide"
                  >
                    Поиск
                  </Button>
                </InputGroup>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md="3" className="d-flex flex-column align-items-start">
                <Button
                  color="primary"
                  className="button-wide"
                  onClick={generateAnaliticsPriceHandler}
                  disabled={loadingGenerate}
                >
                  Сгенерировать
                  {loadingGenerate && (
                    <div className="spinner-wrapper">
                      <Spinner color="danger" size="sm" />
                    </div>
                  )}
                </Button>
                {loadingGenerate && (
                  <>Процесс генерации: {progress?.progress} %</>
                )}
              </Col>

              <Col md="3" className="d-flex flex-column align-items-start">
                <Button onClick={getAnaliticsPriceFile} className="button-wide">
                  Импорт
                </Button>
                <Input
                  type="file"
                  id="analiticsPrice"
                  hidden
                  innerRef={inputFile}
                  onChange={(e) => setFile(e.target.files[0])}
                />
              </Col>
              <Col md="3">
                <Button
                  color="primary"
                  className="button-wide btn btn-primary"
                  onClick={downloadHandler}
                >
                  Скачать
                </Button>
              </Col>
            </Row>
            <Row>
              <Col md="3">Дата обновления: {dateTimeFormat(lastUpdate)}</Col>
            </Row>
          </Form>
        </CardBody>
      </Card>

      <div>
        <AnaliticsPriceTable analiticsPrice={analiticsPrice} />

        <Card>
          <CardBody>
            <Pagination
              totalCount={analiticsPriceCount}
              currentPage={currentPage}
              pageSize={pageSize}
              setCurrentPage={setCurrentPage}
              onChangePageSize={setPageSize}
            />
          </CardBody>
        </Card>
      </div>
    </Container>
  );
};
