import React, { useEffect, useState, useRef } from "react";
import InputMask from "react-input-mask";
import {
  Container,
  Label,
  ButtonGroup,
  Card,
  CardBody,
  Row,
  Col,
  Input,
  Button,
  Alert,
} from "reactstrap";
import { useHistory, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import Select from "react-select";
import { toast } from "react-toastify";
import ru from "date-fns/locale/ru";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CornerRightDown as CornerRightDownIcon } from "react-feather";
import classNames from "classnames";

import { providersSuggestion } from "services/api";
import {
  createOrderToProvider,
  getOrderToProviderOne,
  getOrderToProviderStatuses,
  orderToProviderFileUpload,
  orderToProviderSetDateArrival,
  orderToProviderUploadDocument,
  setOrderToProviderStatus,
  updateOrderToProvider,
} from "services/apiSupply";

import { MySpinner } from "components/MySpinner";
import { dateFormat, dateFormatU } from "utils/format";
import { HeaderPage } from "components/HeaderPage";
import { validHandler } from "utils/validation";
import { OrdersToProviderProductTable } from "features/OrdersToProvider/OrdersToProviderProductTable";
import { OrdersToProviderProductTableNew } from "features/OrdersToProvider/OrdersToProviderProductTableNew";
import { AddProductModal } from "features/OrdersToProvider/AddProductModal";
import { StatusOrderToProvider } from "components/StatusOrderToProvider";

import "./orderToProviderOne.scss";

registerLocale("ru", ru);

export const VALID = ["provider"];

export const TYPES = [
  { value: "express", label: "Экспресс" },
  { value: "stock", label: "Складской" },
  { value: "invoice", label: "Инвойс" },
];

export const OrderToProviderOnePage = () => {
  const history = useHistory();
  const { id } = useParams();
  const [isNew, setIsNew] = useState(false);
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState("");
  const [status, setStatus] = useState({});
  const [products, setProducts] = useState([]);
  const [date, setDate] = useState("");
  const [dateArrival, setDateArrival] = useState("");
  const [createdAt, setCreatedAt] = useState("");
  const [docNum, setDocNum] = useState("");
  const [number, setNumber] = useState("");
  const [provider, setProvider] = useState({});
  const [suggestions, setSuggestions] = useState([]);
  const [admin, setAdmin] = useState({});
  const [type, setType] = useState("");
  const [docLink, setDocLink] = useState("");
  const [errors, setErrors] = useState([]);
  const [needValid, setNeedValid] = useState(false);
  const [error, setError] = useState("");
  const [file, setFile] = useState(null);
  const inputFile = useRef(null);
  const inputDocumentFile = useRef(null);
  const [documentFile, setDocumentFile] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [statuses, setStatuses] = useState([]);

  const [search, setSearch] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);

  const providerMap = (res) =>
    res.map((item) => {
      return { value: item.id, label: item.name };
    });

  useEffect(() => {
    if (search.length < 3) {
      setSuggestions([]);
      return;
    }
    setSearchLoading(true);
    providersSuggestion(search)
      .then((res) => setSuggestions(providerMap(res)))
      .finally(() => setSearchLoading(false));
  }, [search]);

  const getAndSetOrderToProvider = () => {
    if (!id) {
      setIsNew(true);
      return;
    }
    setLoading(true);
    getOrderToProviderOne(id)
      .then((res) => {
        setDate(new Date(res.date));
        setCreatedAt(dateFormat(res.date));
        setNumber(res.number);
        setProvider({
          label: res.provider?.name,
          value: res.provider?.id,
        });
        setProducts(res.products || []);
        setComment(res.comment || "");
        setAdmin(res.admin);
        setStatus(res.status);
        setType(res.type);
        setDocLink(res.docLink);
        setDocNum(res.docNum);
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getAndSetOrderToProvider();
    getOrderToProviderStatuses()
      .then(setStatuses)
      .catch((e) => {
        toast.error(e.response?.data?.message);
      })
      .finally(() => setLoading(false));

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dateValidate = (val) => {
    const dateNow = new Date();
    if (val.getTime() > dateNow.getTime()) {
      toast.error("Дата не может быть больше сегодняшней");
      return;
    }
    setDate(val);
  };

  const updateHandler = () => {
    if (!id) return;

    setLoading(true);
    updateOrderToProvider({
      id,
      provider: provider.value,
      comment,
      type,
      date: dateFormat(date),
      docNum,
    })
      .then((res) => {
        setDate(new Date(res.date));
        setCreatedAt(dateFormat(res.date));
        setNumber(res.number);
        setProvider({
          label: res.provider?.name,
          value: res.provider?.id,
        });
        setProducts(res.products);
        setComment(res.comment || "");
        setStatus(res.status);
        setType(res.type);
        setDocLink(res.docLink);
        toast.success("Изменения успешно сохранены");
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
      })
      .finally(() => setLoading(false));
  };

  const uploadDocument = () => {
    if (!id) return;
    orderToProviderUploadDocument()
      .then((res) => {
        setDocLink(res.docLink);
        toast.success("Файл успешно загружен");
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    const { errors } = validHandler(
      {
        provider: provider.value,
      },
      VALID
    );
    setErrors(errors);
    // eslint-disable-next-line
  }, [provider]);

  const createHandler = () => {
    setNeedValid(true);

    const { valid, errors } = validHandler(
      {
        provider: provider.value,
      },
      VALID
    );

    setErrors(errors);

    if (!valid) return;

    setLoading(true);
    createOrderToProvider({
      provider: provider.value,
      comment,
      products,
      type,
      date: dateFormat(date),
      docNum,
    })
      .then((res) => {
        history.push(res.id);
        toast.success("Заказ поставщику успешно создано");
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
      })
      .finally(() => setLoading(false));
  };

  const renderTitle = () => {
    if (isNew) {
      return `Новый заказ поставщику`;
    }

    return `Заказ поставщику № ${number || ""} от ${provider?.label || ""}`;
  };

  const importHandler = (val) => {
    if (inputFile) {
      inputFile.current.click();
    }
  };

  useEffect(() => {
    if (!file) return;
    setLoading(true);
    const formData = new FormData();
    formData.append("file", file);
    orderToProviderFileUpload(formData)
      .then((res) => {
        setProducts(res);
      })
      .finally(() => {
        setLoading(false);
        inputFile.current.value = "";
      });

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  const statusHandler = (status) => {
    setLoading(true);
    setOrderToProviderStatus({
      id,
      statusId: status?.id,
    })
      .then((res) => {
        setStatus(res.status);
        toast.success("Статус успешно изменен");
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (!documentFile) return;
    setLoading(true);
    const formData = new FormData();
    formData.append("file", documentFile);
    formData.append("id", id || null);
    orderToProviderUploadDocument(formData)
      .then((res) => {
        setDocLink(res.docLink);
        toast.success("Файл успешно загружен");
      })
      .finally(() => {
        setLoading(false);
        inputDocumentFile.current.value = "";
      });

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentFile]);

  const dateArrivalHandler = (val) => {
    setLoading(true);
    setDateArrival(val);

    orderToProviderSetDateArrival({
      id,
      dateArrival: dateFormat(val)
    })
      .then((res) => {
        setProducts(res.products);
        toast.success("Дата поступления успешно обновлена");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const importDocumentHandler = () => {
    if (inputDocumentFile) {
      inputDocumentFile.current.click();
    }
  };

  // const documentHandler = () => {
  //   getOrderToProviderDocument({ id })
  //     .then((res) => {
  //       const url = window.URL.createObjectURL(new Blob([res]));
  //       const link = document.createElement("a");
  //       link.href = url;
  //       link.setAttribute("download", `Чек № ${order.number}.xlsx`);
  //       document.body.appendChild(link);
  //       link.click();
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // };

  return (
    <Container fluid className="p-0 order-to-provider">
      <Helmet>
        <title>{renderTitle()}</title>
      </Helmet>
      <HeaderPage title={renderTitle()}>
        <>
          {!isNew && (
            <>
              <div className="order-to-provider__date-block">
                <Label>Дата</Label>
                <ButtonGroup>
                  <InputMask
                    id="createdAt"
                    name="createdAt"
                    type="text"
                    onChange={() => { }}
                    value={createdAt}
                    mask={"99.99.9999"}
                    className="form-control date"
                    disabled
                  />
                </ButtonGroup>
              </div>
            </>
          )}
        </>
      </HeaderPage>

      {loading && <MySpinner overlay />}
      {error && <Alert color="danger">{error}</Alert>}

      <Card className="order-to-provider__form mb-0">
        <CardBody className="pb-3">
          <Row>
            <Col md="6" className="elem-field">
              <Label className="text-sm-right">Поставщик</Label>
              <Select
                name="provider"
                placeholder="Выберите поставщика"
                className="react-select-container"
                classNamePrefix="react-select"
                options={suggestions}
                isSearchable
                isLoading={searchLoading}
                inputValue={search}
                onInputChange={setSearch}
                value={provider}
                onChange={setProvider}
              />
            </Col>
            <Col md="3" className="elem-field">
              <Label className="text-sm-right">Тип заказа</Label>
              <Select
                name="type"
                placeholder="Тип заказа"
                className="react-select-container"
                classNamePrefix="react-select"
                options={TYPES}
                value={TYPES.find((v) => type === v.value)}
                defaultValue={TYPES.find((v) => "express" === v.value)}
                onChange={(val) => setType(val.value)}
              />
            </Col>
          </Row>
          <Row>
            <Col md="3" className="elem-field">
              <Label className="text-sm-right">Номер документа</Label>
              <Input
                name="docNum"
                placeholder="Номер документа"
                value={docNum}
                onChange={(e) => setDocNum(e.target.value)}
                invalid={needValid && errors.hasOwnProperty("docNum")}
              />
            </Col>
            <Col md="3" className="elem-field">
              <Label className="text-sm-right">Дата документа</Label>
              <DatePicker
                placeholderText="Дата документа"
                selected={date}
                onChange={dateValidate}
                selectsStart
                dateFormat="dd.MM.yyyy"
                locale="ru"
                className={classNames("form-control", {
                  error: needValid && errors.hasOwnProperty("date"),
                })}
              />
            </Col>
            <Col md="4">
              <Label className="text-sm-right">Документ</Label>
              <div>
                <Button onClick={importDocumentHandler}>
                  Загрузить документ
                </Button>
                {docLink && (
                  <a
                    href={`${process.env.REACT_APP_BASE_URL}${docLink}`}
                    className="ml-2"
                  >
                    Скачать
                  </a>
                )}
                <Input
                  type="file"
                  id="document"
                  hidden
                  innerRef={inputDocumentFile}
                  onChange={(e) => setDocumentFile(e.target.files[0])}
                />
              </div>
            </Col>
            <Col md="4">
              <Label className="text-sm-right">Дата поступления</Label>
              <DatePicker
                placeholderText="Дата поступления"
                selected={dateArrival}
                onChange={dateArrivalHandler}
                selectsStart
                dateFormat="dd.MM.yyyy"
                locale="ru"
                className={classNames("form-control", {
                  error: needValid && errors.hasOwnProperty("date"),
                })}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Label className="text-sm-right">Комментарий</Label>
              <Input
                type="textarea"
                name="comment"
                placeholder="Комментарий"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
            </Col>
          </Row>
          {!isNew && (
            <Row>
              <Col md="3">
                <Label className="text-sm-right">Создал заказ поставщику</Label>
                <Input value={admin.name} disabled />
              </Col>
            </Row>
          )}

          <Row>
            <Col md="3">
              <StatusOrderToProvider
                status={status}
                statuses={statuses}
                statusHandler={statusHandler}
              >
                {status.name}
              </StatusOrderToProvider>
            </Col>
          </Row>
        </CardBody>
      </Card>

      {!isNew && (
        <Card className="order-to-provider__button-save mb-0">
          <CardBody className="pt-1">
            <Button
              color="primary"
              onClick={updateHandler}
              className="button-wide"
            >
              Сохранить
            </Button>
          </CardBody>
        </Card>
      )}

      <Card className="order-to-provider-product mb-0">
        <CardBody>
          {loading && <MySpinner overlay />}

          <Row className="order-to-provider-product__toolbar">
            <Col md="12" className="button-block">
              <Button
                color="primary"
                onClick={() => setOpenModal(true)}
                className="button-wide"
              >
                Добавить товар
              </Button>

              <>
                <Button onClick={importHandler}>Импорт</Button>
                <Input
                  type="file"
                  id="orderToProvider"
                  hidden
                  innerRef={inputFile}
                  onChange={(e) => setFile(e.target.files[0])}
                />

                <a
                  href="https://api.lider-truck.ru/files/templates/orderToProviderTemplate.xlsx"
                  className="btn btn-secondary button-wide"
                >
                  Скачать шаблон
                  <CornerRightDownIcon size={15} />
                </a>
              </>
            </Col>
          </Row>
        </CardBody>
      </Card>

      {isNew ? (
        <OrdersToProviderProductTableNew
          products={products}
          setProducts={setProducts}
        />
      ) : (
        <OrdersToProviderProductTable
          id={id}
          products={products}
          setProducts={setProducts}
          isNew={isNew}
          provider={provider}
        />
      )}

      <AddProductModal
        orderToProviderId={id}
        openModal={openModal}
        setOpenModal={setOpenModal}
        setProducts={setProducts}
        setLoading={setLoading}
        provider={provider}
      />

      {products?.length > 0 && (
        <Card>
          <CardBody>
            {isNew && (
              <Button
                color="primary"
                onClick={createHandler}
                className="button-wide"
              >
                Создать заказ поставщику
              </Button>
            )}
          </CardBody>
        </Card>
      )}
    </Container>
  );
};
